import React, { useState, useEffect } from 'react'
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import Title from '../../Title'
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSelector, useDispatch } from 'react-redux'
import { selectMember, selectToken } from '../../features/authSlice/authSlice'
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import utc from 'dayjs/plugin/utc'
import Button from '@mui/material/Button'
import axios from 'axios'

export default function NewAtcBooking(props) {
    const { showForm, closeNewBookingForm, setIsLoading } = props;
    const [showRequest, setShowRequest] = useState(false);
    const member = useSelector(selectMember);
    const token = useSelector(selectToken);
    const [callsign, setCallsign] = useState("");
    const [start, setStart] = useState(null);
    const [end, setEnd] = useState(null);
    const [minEndDateTime, setEndMinDateTime] = useState();
    const [minStartDateTime, setMinStartDateTime] = useState()
    const [loadingButton, setLoadingButton] = useState(false);
    const [submitButton, setSubmitButton] = useState(true);
    const [positions, setPositions] = useState([]);
    const [valid, setValid] = useState({ callsign: false, start: false, end: false });



    const handleSubmit = (e) => {
        e.preventDefault();
        setLoadingButton(true);
        var data = JSON.stringify({
            "callsign": callsign,
            "cid": member.vid,
            "start": start,
            "end": end,
        });

        var config = {
            method: 'post',
            url: process.env.REACT_APP_VATIL_API + '/calendar/newatcbooking',
            headers: {
                'Authorization': `Bearer ${token} `,
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                setTimeout(() => {
                    closeNewBookingForm();
                    setIsLoading(true);
                }, 3000)
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    const handleChange = (event) => {
        setCallsign(event.target.value);
    };


    const handleClose = () => {
        setCallsign(null);
        setStart(null);
        setEnd(null);
        setEndMinDateTime(null);
        setSubmitButton(true);
        setValid({
            start: false,
            end: false,
            callsign: false,
        })
        closeNewBookingForm();
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };


    useEffect(() => {
        dayjs.extend(isSameOrAfter)
        dayjs.extend(utc)
        var data = JSON.stringify({
            "rating": member.atcrating
        });

        var config = {
            method: 'post',
            url: process.env.REACT_APP_VATIL_API + '/calendar/getpositions',
            headers: {
                'Authorization': `Bearer ${token} `,
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                setPositions(response.data);
                setShowRequest(showForm);
                setMinStartDateTime(dayjs().subtract(2, 'hour'))
            })
            .catch(function (error) {
                console.log(error);
            });

        validateForm();

    }, [showForm, callsign, start, end, minEndDateTime]);

    const validateForm = () => {
        if (valid.callsign && valid.start && valid.end) {
            setSubmitButton(false)
        } else {
            setSubmitButton(true);

        }
    }

    return (
        <Modal
            open={showRequest}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ marginBottom: 20 }}
        >
            <Stack component="form" noValidate spacing={3} sx={style}>
                <Title >New ATC Booking</Title>
                <h5>Note: ATC booking needs to be at least 90 min.</h5>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Position</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={callsign}
                        label="Position"
                        onChange={async (newValue) => {
                            setCallsign(newValue.target.value);
                            setValid({
                                ...valid,
                                callsign: true,
                            })
                        }}
                    >
                        {positions.map((pos, i) => { return <MenuItem key={i} value={pos.name}>{pos.name}</MenuItem> })}
                    </Select>
                </FormControl>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                        label="Start Time (UTC)"
                        value={start}
                        onChange={(newValue) => {
                            if (newValue === null) {
                                setStart(null);
                                setEnd(null);
                                setValid({
                                    ...valid,
                                    start: false,
                                })
                            } else {
                                let minEndDateTime = newValue.clone().add(90, 'minute');
                                setEndMinDateTime(minEndDateTime);
                                setStart(newValue);
                                setEnd(null);
                                setValid({
                                    ...valid,
                                    start: true,
                                    end: false,
                                })
                            }

                        }}
                        disablePast
                        minDateTime={minStartDateTime}
                        ampm={false}
                        inputFormat="DD/MM/YYYY HH:mm"
                        renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                        label="End Time (UTC)"
                        value={end}
                        onChange={(newValue) => {
                            if (newValue === null) {
                                setEnd(newValue);
                                setValid({
                                    ...valid,
                                    end: false,
                                })
                            } else {
                                if (newValue.isSameOrAfter(minEndDateTime)) {
                                    setEnd(newValue);
                                    setValid({
                                        ...valid,
                                        end: true,
                                    })
                                } else {
                                    setEnd(newValue);
                                    setValid({
                                        ...valid,
                                        end: false,
                                    })
                                }
                            }
                        }}
                        minDateTime={minEndDateTime}
                        ampm={false}
                        inputFormat="DD/MM/YYYY HH:mm"
                        renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
                <LoadingButton
                    onClick={handleSubmit}
                    endIcon={<SendIcon />}
                    loading={loadingButton}
                    loadingPosition="end"
                    variant="contained"
                    disabled={submitButton}
                >
                    Submit
                </LoadingButton>
            </Stack>
        </Modal>
    )
}