import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectMember, selectToken, selectValid } from '../../features/authSlice/authSlice'
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Title from '../../Title'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import axios from 'axios'
import SendIcon from '@mui/icons-material/Send';
import LoadingButton from '@mui/lab/LoadingButton';


export default function ContactForm() {
    const member = useSelector(selectMember)
    const token = useSelector(selectToken);
    const [subject, setSubject] = useState();
    const [message, setMessage] = useState();
    const [department, setDepartment] = useState("director@vatil.org.il");
    const [button, setButton] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };


    const handlesubmit = (e) => {
        e.preventDefault();
        setButtonLoading(true);
        var data = JSON.stringify({
            "full_name": member.first_name + " " + member.last_name,
            "vid": member.vid,
            "email_address": member.email,
            "message": message,
            "subject": subject,
            "department": department
        });

        var config = {
            method: 'post',
            url: process.env.REACT_APP_VATIL_API + '/general/contactus',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token} `
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                handleOpen();
                setButtonLoading(false);
                setButton(true);
                setDepartment("director@vatil.org.il");
                setSubject("");
                setMessage("");
            })
            .catch(function (error) {
                console.log(error);
            });

    }


    useEffect(() => {
        if (message && subject && department) {
            setButton(false);
        } else {
            setButton(true);
        }
    }, [subject, message, department])


    return (
        <div style={{ marginLeft: 200 }}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={8} lg={9}>
                    <Paper sx={{ height: "650px" }}>
                        <Title >Contact us!</Title>
                        <form style={{ width: "70%", marginLeft: "10px" }}>
                            <TextField
                                disabled
                                id="outlined-disabled"
                                label="Name"
                                value={member.first_name + " " + member.last_name}
                                margin="normal"
                                fullWidth
                            />
                            <TextField
                                disabled
                                id="outlined-disabled"
                                label="VATSIM ID"
                                value={member.vid}
                                margin="normal"
                                fullWidth
                            />
                            <TextField
                                disabled
                                id="outlined-disabled"
                                label="Email Address"
                                value={member.email}
                                margin="normal"
                                fullWidth
                            />
                            <FormControl fullWidth sx={{ marginTop: "5px" }}>
                                <InputLabel >Department</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={department}
                                    label="Department"
                                    onChange={(event) => setDepartment(event.target.value)}
                                    margin="normal"
                                >
                                    <MenuItem value={"director@vatil.org.il"}>Division Director</MenuItem>
                                    <MenuItem value={"atc@vatil.org.il"}>ATC Training</MenuItem>
                                    <MenuItem value={"events@vatil.org.il"}>Events And Marketing</MenuItem>
                                    <MenuItem value={"webmaster@vatil.org.il"}>Webmaster</MenuItem>
                                </Select>
                            </FormControl>
                            <TextField
                                id="outlined-disabled"
                                label="Subject"
                                margin="normal"
                                fullWidth
                                required
                                onChange={(event) => setSubject(event.target.value)}
                                value={subject}
                            />
                            <TextField
                                id="outlined-disabled"
                                label="Your Message"
                                margin="normal"
                                fullWidth
                                required
                                multiline
                                rows={5}
                                onChange={(event) => setMessage(event.target.value)}
                                value={message}
                            />
                            <LoadingButton
                                onClick={handlesubmit}
                                endIcon={<SendIcon />}
                                loading={buttonLoading}
                                loadingPosition="end"
                                variant="contained"
                                disabled={button}
                            >
                                Submit
                            </LoadingButton>
                        </form>

                    </Paper>
                </Grid>
            </Grid >
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Message Sent!
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Thank you for contacting us! {<br />}
                        You meesage was delivered to the relevant authorities.
                    </Typography>
                </Box>
            </Modal>
        </div>
    )

}