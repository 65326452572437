import React from 'react'
import MenuItem from '@mui/material/MenuItem';
import { NavLink } from 'react-router-dom'
import { selectMember, selectToken, selectValid } from '../../features/authSlice/authSlice'
import { useSelector, useDispatch } from 'react-redux'
import Cookies from 'js-cookie';

export default function TopBarMenuiItems() {
    const member = useSelector(selectMember)

    const handleLogout = () => {
        Cookies.remove("token", { path: "/", domain: ".vatil.org.il" })
        window.location.reload(false);
    }


    if (member.role === 'Admin') {
        return (
            <div>
                <MenuItem ><NavLink style={{ textDecoration: 'none', color: 'black' }} to="/profile">Profile </NavLink></MenuItem>
                <MenuItem ><NavLink style={{ textDecoration: 'none', color: 'black' }} to="/admin">Admin Panel </NavLink></MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </div>
        )
    } else {
        return (
            <div>
                <MenuItem ><NavLink style={{ textDecoration: 'none', color: 'black' }} to="/profile">Profile </NavLink></MenuItem>
                <MenuItem >Logout</MenuItem>
            </div>
        )
    }


}