import React from 'react'
import Flights from '../Flights/Flights'
import LiveAtc from '../LiveAtc/LiveAtc'
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Orders from '../../../Orders'
import UpcomingActivity from '../UpcomingActivity/UpcomingActivity'
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';



export default function Main(props) {





    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={8} lg={9}>
                <Paper>
                    <Flights />
                </Paper>

            </Grid>
            <Grid item xs={12} md={4} lg={3}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 468, }}>
                    <LiveAtc />
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <UpcomingActivity />
                </Paper>
            </Grid>
        </Grid>
    )
}