import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux'
import { setFlights, selectArrivals, selectDepartures } from '../features/flightSlice/flightSlice'
import { selectToken } from '../features/authSlice/authSlice'
import { DataGrid } from '@mui/x-data-grid';

const columnsdepartures = [
    { field: 'callsign', headerName: 'Callsign', width: 200 },
    { field: 'destination', headerName: 'Destination', width: 200 },
    { field: 'aircraft', headerName: 'Aircraft', width: 200 },
    { field: 'status', headerName: 'Status', width: 200 },
];

const columnsarrivals = [
    { field: 'callsign', headerName: 'Callsign', width: 150 },
    { field: 'from', headerName: 'from', width: 150 },
    { field: 'aircraft', headerName: 'Aircraft', width: 150 },
    { field: 'eta', headerName: 'ETA (UTC)', width: 150 },
    { field: 'status', headerName: 'Status', width: 150 },
];

export default function TableFlights(props) {
    const { type } = props;
    const [isLoading, setIsLoading] = useState(true);
    const [duration, setDuration] = useState(2000);
    const token = useSelector(selectToken);
    const arrivals = useSelector(selectArrivals);
    const departures = useSelector(selectDepartures);
    const dispatch = useDispatch()

    useEffect(() => {
        const interval = setInterval(async () => {
            var config = {
                method: 'get',
                url: process.env.REACT_APP_VATIL_API + '/general/liveflight/llbg',
                headers: {
                    'Authorization': `Bearer ${token} `
                }
            };
            await axios(config)
                .then(function (response) {
                    dispatch(setFlights({ arrivals: response.data.arrivals, departures: response.data.departures }));
                    setDuration(60000);
                    setIsLoading(false);
                })
                .catch(function (error) {
                    console.log(error);
                });
        }, duration)
        return () => clearInterval(interval);
    }, [isLoading])

    if (isLoading) {
        <h1>Loading...</h1>
    } else {
        if (type) {
            return (
                <div style={{ height: 371, width: '100%' }}>
                    <DataGrid
                        getRowId={(row) => row.callsign}
                        rows={arrivals}
                        columns={columnsarrivals}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        loading={isLoading}
                    />
                </div>
            );
        } else {
            return (
                <div style={{ height: 371, width: '100%' }}>
                    <DataGrid
                        getRowId={(row) => row.callsign}
                        rows={departures}
                        columns={columnsdepartures}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        loading={isLoading}
                    />
                </div>
            );
        }
    }

}