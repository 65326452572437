import React, { useState, useEffect } from "react"
import { DataGrid } from '@mui/x-data-grid';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import axios from 'axios'
import { selectMember, selectToken, selectValid } from '../../../features/authSlice/authSlice'
import { useSelector, useDispatch } from 'react-redux'


export default function Charts(props) {
    const { airport, isLoading } = props;
    const [scenery, setScenery] = useState();
    const [mounted, setMounted] = useState(false);
    const token = useSelector(selectToken);
    const loadingRows = [{ id: 1, simulator: "loading", version: 'loading', link: 'loading' }];


    const columns = [
        { field: 'simulator', headerName: 'Flight Simulator', width: 400 },
        { field: 'version', headerName: 'Version', width: 300 },
        {
            field: 'link',
            headerName: 'Link',
            width: 230,
            renderCell: (cellValues) => {
                return (
                    <Button
                        sx={{ backgroundColor: "#0a1a40" }}
                        variant="contained"
                        onClick={async (e) => {
                            window.open(cellValues.row.link, '_blank');
                        }}
                    >
                        View
                    </Button>
                )
            }
        }
    ];

    useEffect(() => {
        if (airport.airport.icao) {
            var data = JSON.stringify({
                "airportId": airport.airport._id
            });
            var config = {
                method: 'post',
                url: process.env.REACT_APP_VATIL_API + '/airports/getscenerybyairport',
                headers: {
                    'Authorization': `Bearer ${token} `,
                    'Content-Type': 'application/json'
                },
                data: data
            };
            axios(config)
                .then(function (response) {
                    setScenery(response.data);
                    setMounted(true)
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }, [mounted])


    if (!mounted) {
        return (
            <div style={{ height: 600, width: '100%' }}>
                <Typography variant="h5" component="h2">
                    Charts
                </Typography>
                <DataGrid
                    rows={loadingRows}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    hideColumnsHeader
                    components={{
                        LoadingOverlay: LinearProgress,
                    }}
                    loading={true}
                />
            </div>
        )
    } else {
        return (
            <div style={{ height: 600, width: '100%', marginBottom: 50 }}>
                <Typography variant="h5" component="h2">
                    Charts
                </Typography>
                <DataGrid
                    rows={scenery}
                    columns={columns}
                    pageSize={50}
                    rowsPerPageOptions={[50]}
                    hideColumnsHeader
                    components={{
                        LoadingOverlay: LinearProgress,
                    }}
                    getRowId={(row) => row.link}
                />
            </div>
        )
    }

}
