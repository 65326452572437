import { configureStore } from '@reduxjs/toolkit'
import authReducer from './features/authSlice/authSlice'
import liveinfoReducer from './features/infoSlice/infoSlice'
import flightReducer from './features/flightSlice/flightSlice'
import atcReducer from './features/atcSlice/atcSlice'

export default configureStore({
    reducer: {
        auth: authReducer,
        liveinfo: liveinfoReducer,
        flight: flightReducer,
        atc: atcReducer
    }
})