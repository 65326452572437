import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import dayjs from 'dayjs';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Title from '../../../Title';
import { selectMember, selectToken } from '../../../features/authSlice/authSlice';
import AtcShifts from './AtcShifts';
import EventImage from './EventImage';

export default function UpcomingEvent() {
    const member = useSelector(selectMember);
    const token = useSelector(selectToken);
    const [event, setEvent] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [title, setTitle] = useState();
    const [description, setDescription] = useState();
    const [start, setStart] = useState();
    const [end, setEnd] = useState();


    function replaceWithBr(string) {
        return string.replace(/\n/g, "<br />")
    }

    useEffect(() => {
        var config = {
            method: 'get',
            url: process.env.REACT_APP_VATIL_API + '/events/getevents',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        axios(config)
            .then(function (response) {
                setTimeout(() => {
                    if (response.data.length > 0) {
                        setEvent(response.data[0]);
                        setTitle(response.data[0].title);
                        setDescription(response.data[0].description)
                        setStart(moment(response.data[0].start).format("DD/MM/YYYY HH:mm"))
                        setEnd(moment(response.data[0].end).format("DD/MM/YYYY HH:mm"))
                        setIsLoading(false);
                    } else {
                        setEvent(null);
                        setIsLoading(false);
                    }
                }, 2500)
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [isLoading])

    if (isLoading) {
        return (
            <Paper >
                <Title >Upcoming Event</Title>
                <div style={{ height: 600, width: '100%' }}>
                    <LinearProgress />
                </div>
            </Paper>
        )
    } else {
        if (!event) {
            return (
                <Paper >
                    <Title >Upcoming Event</Title>
                    <div style={{ height: 600, width: '100%' }}>
                        <h1>There is no upcoming event</h1>
                    </div>
                </Paper>
            )
        } else {
            if ((member.atcrating > 1 && member.division === "IL") || member.role === "Admin") {
                return (
                    <Paper >
                        <Title >Upcoming Event</Title>
                        <EventImage url={event.logo} width={1000} height={562} />
                        <h2>{event.title}</h2>
                        <h3>{dayjs(event.start).format('DD/MM/YYYY')}: {dayjs(event.start).format('HH:mm')}-{dayjs(event.end).format('HH:mm')} (UTC)</h3>
                        <p dangerouslySetInnerHTML={{ __html: replaceWithBr(event.description) }} />
                        Route:<br></br>
                        {event.route}<br></br>
                        <AtcShifts event={event} />
                    </Paper >
                )
            } else {
                return (
                    <Paper >
                        <Title >Upcoming Event</Title>
                        <EventImage url={event.logo} width={1000} height={562} />
                        <h2>{event.title}</h2>
                        <h3>{dayjs(event.start).format('DD/MM/YYYY')}: {dayjs(event.start).format('HH:mm')}-{dayjs(event.end).format('HH:mm')} (UTC)</h3>
                        <p dangerouslySetInnerHTML={{ __html: replaceWithBr(event.description) }} />
                        Route:<br></br>
                        {event.route}<br></br>

                    </Paper>
                )
            }

        }
    }

}