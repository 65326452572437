import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { selectMember, selectToken, selectValid } from '../../../features/authSlice/authSlice'
import { useSelector, useDispatch } from 'react-redux'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Book from './Book'
import CancelBooking from './CancelBooking';
import './UpcomingEvent.css';
import dayjs from 'dayjs'


export default function AtcShifts(props) {
    const { event } = props;
    const token = useSelector(selectToken);
    const member = useSelector(selectMember);
    const [isLoading, setIsLoading] = useState(true);
    const [shifts, setShifts] = useState([]);
    const [shift, setShift] = useState();
    const [name, setName] = useState();
    const [showBook, setShowBook] = useState(false)
    const [showCancel, setShowCancel] = useState(false);


    const closeBookingForm = () => {
        setShowBook(false);
    }

    const closeCancelForm = () => {
        setShowCancel(false);
    }

    useEffect(() => {
        var data = JSON.stringify({
            "id": event._id
        });
        var config = {
            method: 'post',
            url: process.env.REACT_APP_VATIL_API + '/events/getshiftsbyevent',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                setShifts(response.data);
                setIsLoading(false);
            })
            .catch(function (error) {
                console.log(error);
            });

    }, [isLoading])




    if (shifts.length > 0 && ((member.atcrating > 1 && member.division === "IL") || member.role === "Admin")) {
        return (
            <TableContainer component={Paper}>
                <h2>ATC Shifts</h2>
                <Table sx={{ width: "80%", border: "groove" }} aria-label="simple table" >
                    <TableHead>
                        <TableRow>
                            <TableCell width="5%"></TableCell>
                            {shifts[0].shifts.map(shift => {
                                return (
                                    <TableCell align="center" ><b>{shift.start}-{shift.end}</b></TableCell>
                                )
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {shifts.map(shift => {
                            return (
                                <TableRow key={shift.position_name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell><b>{shift.position_name}</b></TableCell>
                                    {shift.shifts.map(element => {
                                        if (element.controller_name) {
                                            if (element.controller_name === member.first_name + " " + member.last_name) {
                                                return (
                                                    <TableCell align="center" key={element._id}>
                                                        <Button
                                                            sx={{ backgroundColor: "#a1322a" }}
                                                            id='cancelbutton'
                                                            variant="contained"
                                                            onClick={async (e) => {
                                                                setShift(element);
                                                                setName(shift.position_name)
                                                                setShowCancel(true);
                                                            }}
                                                        >
                                                            Cancel
                                                        </Button>
                                                    </TableCell>
                                                )
                                            } else {
                                                return (
                                                    <TableCell align="center">{element.controller_name}</TableCell>
                                                )
                                            }

                                        } else {
                                            return (
                                                <TableCell align="center">
                                                    <Button
                                                        sx={{ backgroundColor: "#0a1a40" }}
                                                        variant="contained"
                                                        onClick={async (e) => {
                                                            setShift(element);
                                                            setName(shift.position_name)
                                                            setShowBook(true);
                                                            // handleClick(event, cellValues);
                                                        }}
                                                    >
                                                        Book
                                                    </Button>
                                                </TableCell>
                                            )
                                        }
                                    })}
                                </TableRow>
                            )
                        })}

                    </TableBody>
                </Table >
                <Book showBook={showBook} closeBookingForm={closeBookingForm} setIsLoading={setIsLoading} shift={shift} name={name} />
                <CancelBooking showCancel={showCancel} closeBookingForm={closeCancelForm} setIsLoading={setIsLoading} shift={shift} name={name} />
            </TableContainer >
        );
    } else {
        if (shifts.length < 1) {
            return null;
        } else {
            return "Loading ATC shifts..."
        }
    }

}