import { createSlice } from "@reduxjs/toolkit";


export const infoSlice = createSlice({
    name: 'liveinfo',
    initialState: {
        atc: null,
        arrivals: null,
        departures: null,
    },
    reducers: {
        setInfo: (state, action) => {
            const { departures, arrivals, atcnum } = action.payload;
            state.atc = atcnum;
            state.departures = departures;
            state.arrivals = arrivals;
        }
    }


})

export const { setInfo } = infoSlice.actions;
export const selectAtc = (state) => state.liveinfo.atc;
export const selectDepartures = (state) => state.liveinfo.departures;
export const selectArrivals = (state) => state.liveinfo.arrivals;
export default infoSlice.reducer;