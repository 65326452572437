import React from 'react'
import { Routes, Route, Navigate } from "react-router-dom"
import ProtectedRoutes from '../ProtectedRoutes/ProtectedRoutes'
import AtcProtectedRoutes from '../AtcProtectedRoutes/AtcProtectedRoutes'
import Main from '../../Main/Main'
import Profile from '../../../Profile/Profile'
import ContactForm from '../../../ContactForm/ContactForm'
import Policy from '../../../Policy/Policy'
import AtcRoster from '../../../AtcRoster/AtcRoster'
import UpcomingEvent from '../../UpcomingEvent/UpcomingEvent'
import AtcRequests from '../../../AtcRequests/AtcRequests'
import AirportMain from '../../../Airport/AirportMain/AirportMain'
import AtcBooking from '../../../AtcBooking/AtcBooking'
import Sop from '../../../Sop/Sop'
import AdminMain from '../../../Admin/AdminMain/AdminMain'
import NewAtc from '../../../NewAtc/NewAtc'
import AtcFeedback from '../../../AtcFeedback/AtcFeedback'
import AtcMaterials from '../../../AtcMaterials/AtcMaterials'

export default function MainRoutes(props) {



    return (
        <Routes>
            <Route path="/" element={<ProtectedRoutes />}>
                <Route path="/" element={<ProtectedRoutes roleRequired={"Member" | "Admin"} />}>
                    <Route path="/" element={<Navigate replace to="home" />} />
                    <Route path="home" element={<Main />} />
                    <Route path="newatcform" element={<NewAtc />} />
                    <Route path="profile" element={<Profile />} />
                    <Route path="contact" element={<ContactForm />} />
                    <Route path="policy" element={<Policy />} />
                    <Route path="atcroster" element={<AtcRoster />} />
                    <Route path="atcfeedback" element={<AtcFeedback />} />
                    <Route path="event" element={<UpcomingEvent />} />
                    <Route path="atcrequests" element={<AtcRequests />} />
                    <Route path="llbg" element={<AirportMain icao="llbg" />} />
                    <Route path="ller" element={<AirportMain icao="ller" key={window.location.pathname} />} />
                    <Route path="sop" element={<Sop />} />
                    <Route path='/sectorfiles' component={() => {
                        window.location.href = 'http://files.aero-nav.com/LLLL';
                        return null;
                    }} />
                </Route>
                <Route path="/admin" element={<ProtectedRoutes roleRequired="Admin" />}>
                    <Route path="/admin" element={<AdminMain />} />
                </Route>
                <Route path="/atc" element={<AtcProtectedRoutes />}>
                    <Route path="/atc/booking" element={<AtcBooking />} />
                    <Route path="/atc/materials" element={<AtcMaterials />} />
                </Route>
            </Route>
        </Routes >
    )
}