import React, { useState, useEffect } from 'react'
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import Title from '../../Title'
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSelector, useDispatch } from 'react-redux'
import { selectMember, selectToken } from '../../features/authSlice/authSlice'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import dayjs from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import axios from 'axios'

export default function NewRequestForm(props) {
    const { showForm, closeRequestForm, setIsLoading } = props;
    const [showRequest, setShowRequest] = useState(false);
    const member = useSelector(selectMember);
    const token = useSelector(selectToken);
    const [title, setTitle] = useState();
    const [pilots, setPilots] = useState();
    const [departure, setDeparture] = useState();
    const [destination, setDestination] = useState();
    const [start, setStart] = useState(null);
    const [end, setEnd] = useState(null);
    const [minEndDateTime, setEndMinDateTime] = useState(null);
    const [minStartDateTime, setMinStartDateTime] = useState()
    const [description, setDescription] = useState();
    const [loadingButton, setLoadingButton] = useState(false);
    const [submitButton, setSubmitButton] = useState(true);
    const [valid, setValid] = useState({ start: false, end: false, pilots: false });

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoadingButton(true);
        var data = JSON.stringify({
            "vid": member.vid,
            "title": title,
            "pilots": pilots,
            "departureIcao": departure.toUpperCase(),
            "landingIcao": destination.toUpperCase(),
            "route": departure + "-" + destination,
            "description": description,
            "start": start,
            "end": end
        });

        var config = {
            method: 'post',
            url: process.env.REACT_APP_VATIL_API + '/calendar/newatcrequest',
            headers: {
                'Authorization': `Bearer ${token} `,
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                setTimeout(() => {
                    closeRequestForm();
                    setIsLoading(true);
                }, 3000)
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    const handleClose = () => {
        setTitle(null);
        setPilots(null);
        setDeparture(null);
        setDestination(null);
        setStart(null);
        setEnd(null);
        setDescription(null);
        setEndMinDateTime(null);
        setSubmitButton(true);
        closeRequestForm();
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };


    useEffect(() => {
        dayjs.extend(isSameOrAfter)
        setMinStartDateTime(dayjs().subtract(2, 'hour'))
        setShowRequest(showForm);
        if (title && valid.pilots && departure && destination && valid.start && valid.end && description) {
            setSubmitButton(false);
        } else {
            setSubmitButton(true);
        }
    }, [title, pilots, departure, destination, start, end, description, showForm])




    return (
        <Modal
            open={showRequest}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Stack component="form" noValidate spacing={3} sx={style}>
                <Title >New ATC Request Form</Title>
                <h5>Requirements for ATC request: 15 pilots and 90 minutes of ATC service.</h5>
                <TextField
                    id="outlined-disabled"
                    label="Request Title"
                    value={title}
                    margin="normal"
                    fullWidth
                    onChange={(e) => {
                        setTitle(e.target.value);
                    }}
                />
                <TextField
                    id="outlined-disabled"
                    label="Number Of Pilots Expected"
                    margin="normal"
                    type="number"
                    fullWidth
                    InputProps={{ inputProps: { min: 15 } }}
                    onChange={(e) => {
                        if (e.target.value < 15) {
                            setPilots(e.target.value);
                            setValid({
                                ...valid,
                                pilots: false,
                            })
                        } else {
                            setPilots(e.target.value);
                            setValid({
                                ...valid,
                                pilots: true,
                            })
                        }

                    }}
                />
                <TextField
                    id="outlined-disabled"
                    label="Departure ICAO"
                    margin="normal"
                    fullWidth
                    onChange={(e) => {
                        setDeparture(e.target.value);
                    }}
                />
                <TextField
                    id="outlined-disabled"
                    label="Destination ICAO"
                    margin="normal"
                    fullWidth
                    onChange={(e) => {
                        setDestination(e.target.value);
                    }}
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                        renderInput={(params) => <TextField {...params} required />}
                        label="Start Time (UTC)"
                        onChange={(newValue) => {
                            if (newValue === null) {
                                setStart(null);
                                setEnd(null);
                                setValid({
                                    ...valid,
                                    start: false,
                                })
                            } else {
                                let minEndDateTime = newValue.clone().add(90, 'minute');
                                setEndMinDateTime(minEndDateTime);
                                setStart(newValue);
                                setEnd(null);
                                setValid({
                                    ...valid,
                                    start: true,
                                    end: false,
                                })
                            }

                        }}
                        value={start}
                        ampm={false}
                        minDateTime={minStartDateTime}
                        disablePast
                        inputFormat="DD/MM/YYYY HH:mm"
                    />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                        renderInput={(params) => <TextField {...params} required />}
                        label="End Time (UTC)"
                        onChange={(newValue) => {
                            if (newValue === null) {
                                setEnd(null);
                                setValid({
                                    ...valid,
                                    end: false,
                                })
                            } else {
                                if (newValue.isSameOrAfter(minEndDateTime)) {
                                    setEnd(newValue);
                                    setValid({
                                        ...valid,
                                        end: true,
                                    })
                                } else {
                                    setEnd(newValue);
                                    setValid({
                                        ...valid,
                                        end: false,
                                    })
                                }
                            }
                        }}
                        value={end}
                        minDateTime={minEndDateTime}
                        ampm={false}
                        inputFormat="DD/MM/YYYY HH:mm"
                    />
                </LocalizationProvider>
                <TextField
                    id="outlined-disabled"
                    label="Request Description"
                    margin="normal"
                    fullWidth
                    required
                    multiline
                    rows={5}
                    onChange={(e) => {
                        setDescription(e.target.value);
                    }}
                />


                <LoadingButton
                    onClick={handleSubmit}
                    endIcon={<SendIcon />}
                    loading={loadingButton}
                    loadingPosition="end"
                    variant="contained"
                    disabled={submitButton}
                >
                    Submit
                </LoadingButton>
            </Stack>
        </Modal>


    )
}