import React, { useState, useEffect } from "react"
import { DataGrid } from '@mui/x-data-grid';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';


export default function Details(props) {
    const { airport, isLoading } = props;
    const loadingRows = [{ id: 1, field: "loading", value: 'loading' }];


    const columns = [
        { field: 'field', headerName: 'Field', width: 230 },
        { field: 'value', headerName: 'Value', width: 750 },

    ];

    const runwayColumns = [
        { field: 'identifier', headerName: 'Identifier', width: 80 },
        { field: 'course', headerName: 'Course', width: 80 },
        { field: 'length', headerName: 'Length', width: 150 },
        { field: 'elevation', headerName: 'Elevation', width: 100 },
        { field: 'latitude', headerName: 'Latitude', width: 150 },
        { field: 'longitude', headerName: 'Longitude', width: 150 },
        { field: 'ils', headerName: 'ILS', width: 60 },
        { field: 'ilsfreq', headerName: 'ILS Frequency', width: 150 },
        { field: 'ilscourse', headerName: 'ILS Course', width: 100 },
        { field: 'gp', headerName: 'GP', width: 10 },
    ]

    const options = {
        options: { customHeadRender: () => null }
    }



    if (isLoading) {
        return (
            <div style={{ height: 600, width: '100%' }}>
                <Typography variant="h5" component="h2">
                    General Details
                </Typography>
                <DataGrid
                    rows={loadingRows}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    hideColumnsHeader
                    loading={isLoading}
                    components={{
                        LoadingOverlay: LinearProgress,
                    }}
                />
            </div>
        )
    } else {
        return (
            <div>
                <div style={{ height: 650, width: '100%', marginBottom: 55 }}>
                    <Typography variant="h5" component="h2">
                        {airport.airport.name + ` (` + airport.airport.icao + ')'}
                    </Typography>
                    <DataGrid
                        rows={[
                            { id: 1, field: "ICAO", value: airport.airport.icao },
                            { id: 2, field: "IATA", value: airport.airport.iata },
                            { id: 3, field: "METAR", value: airport.metar },
                            { id: 4, field: "Name", value: airport.airport.name },
                            { id: 5, field: "City", value: airport.airport.city },
                            { id: 6, field: "Elevation", value: airport.airport.elevation + "ft" },
                            { id: 7, field: "Transition Altitude", value: airport.airport.transition_altitude },
                            { id: 8, field: "Transition Level", value: airport.airport.transition_level },
                            { id: 9, field: "Latitude", value: airport.airport.latitude },
                            { id: 10, field: "Longitude", value: airport.airport.longitude },
                        ]}
                        // filterModel={{
                        //     items: [{ columnField: 'field', operatorValue: 'contains', value: 'ICAO' }],
                        // }}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        hideColumnsHeader
                        loading={false}
                        components={{
                            LoadingOverlay: LinearProgress,
                        }}
                    />
                </div>
                <div style={{ height: 450, width: '100%', marginBottom: 50 }}>
                    <Typography variant="h5" component="h2">
                        Runways Details
                    </Typography>
                    <DataGrid
                        rows={airport.runways}
                        columns={runwayColumns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        hideColumnsHeader
                        loading={false}
                        components={{
                            LoadingOverlay: LinearProgress,
                        }}
                        getRowId={(row) => row.identifier}
                    />
                </div>
            </div >
        )
    }

}


