import React, { useState, useEffect, useRef } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { selectToken } from '../../../../features/authSlice/authSlice';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Fade from '@mui/material/Fade';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function VisitorController(props) {
    const { showVisitor, closeVisitor, setIsLoading } = props;
    const [buttonLoading, setButtonLoading] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [vid, setVid] = useState();
    const [name, setName] = useState();
    const [division, setDivision] = useState();
    const [positions, setPositions] = useState();
    const [positionsDisabled, setPositionsDisabled] = useState(true);
    const [member, setMember] = useState();
    const [alert, setAlert] = useState(false);
    const [severity, setSeverity] = useState("error");
    const [alertMessage, setAlertMessage] = useState();
    const token = useSelector(selectToken);
    const handleClose = () => {
        setName('');
        setDivision('')
        setPositions('')
        closeVisitor();
    }

    const fetchMember = () => {
        if (vid) {
            setName('Loading...');
            setDivision('Loading...')
            setPositions('');
            setPositionsDisabled(true);
            setTimeout(() => {
                var data = JSON.stringify({
                    "vid": Number(vid)
                });
                var config = {
                    method: 'post',
                    url: process.env.REACT_APP_VATIL_API + '/general/getmembervatsim',
                    headers: {
                        'Authorization': `Bearer ${token} `,
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                axios(config)
                    .then(function (response) {
                        if (response.data) {
                            const { name_first, name_last, division } = response.data;
                            if (division !== 'IL') {
                                setName(name_first + " " + name_last);
                                setDivision(division);
                                setPositionsDisabled(false);
                                setMember(response.data);
                            } else {
                                setAlertMessage("Visiting controller cannot be division member.")
                                setAlert(true);
                                setName('Loading...');
                                setDivision('Loading...')
                                setPositionsDisabled(true);
                                setVid(null);
                                setTimeout(() => { setAlert(false); }, 5000);
                            }

                        } else {
                            setName('Not Found');
                            setDivision('Not Found')
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }, 3000)
        }
    }

    const handleSubmit = () => {
        setButtonLoading(true);
        var data = JSON.stringify({
            "member": member,
            "positions": positions
        });

        var config = {
            method: 'post',
            url: process.env.REACT_APP_VATIL_API + '/general/addvisitoratc',
            headers: {
                'Authorization': `Bearer ${token} `,
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                setName('');
                setDivision('')
                setPositions('')
                handleClose();
                setIsLoading(true);
            })
            .catch(function (error) {
                console.log(error);
            });

    }



    useEffect(() => {
        if (!vid) {
            setName('');
            setDivision('');
            setPositions('');
            setButtonDisabled(true);
        }
        setButtonDisabled(true);
        const delay = setTimeout(() => {
            fetchMember();
        }, 3000)

        return () => clearTimeout(delay)

    }, [vid])


    return (
        <Modal
            open={showVisitor}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Add Visiting Controller
                </Typography>
                <form style={{ width: "90%", marginLeft: "10px" }}>
                    <TextField
                        id="outlined-disabled"
                        label="VATSIM ID"
                        value={vid}
                        margin="normal"
                        onChange={(e) => { setVid(e.target.value); }}
                        fullWidth
                    />
                    <TextField
                        id="outlined-disabled"
                        label="Name"
                        value={name}
                        InputLabelProps={{ shrink: name }}
                        margin="normal"
                        disabled
                        fullWidth
                        autoFocus
                    />
                    <TextField
                        id="outlined-disabled"
                        label="Division"
                        value={division}
                        InputLabelProps={{ shrink: division }}
                        margin="normal"
                        disabled
                        fullWidth
                        autoFocus
                    />
                    <TextField
                        id="outlined-disabled"
                        label="Positions"
                        value={positions}
                        margin="normal"
                        onChange={(e) => { if (e.target.value) { setPositions(e.target.value); setButtonDisabled(false); } else { setPositions(''); setButtonDisabled(true); } }}
                        disabled={positionsDisabled}
                        fullWidth
                    />
                    <Fade in={alert}>
                        <Alert severity={severity}>{alertMessage}</Alert>
                    </Fade>
                    <LoadingButton color="primary" variant="contained" size="large" disabled={buttonDisabled} loading={buttonLoading} onClick={(e) => { handleSubmit(); }}>Submit</LoadingButton>&nbsp; &nbsp;

                </form>
            </Box>
        </Modal>
    );
}
