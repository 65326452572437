import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectMember, selectToken, selectValid } from '../../features/authSlice/authSlice'
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Title from '../../Title'
import TextField from '@mui/material/TextField';



export default function Profile() {
    const member = useSelector(selectMember);
    const [atcrating, setAtcrating] = useState("obs");
    const [subdivision, setSubdivision] = useState("");

    useEffect(() => {
        if (member.atcrating === 2) {
            setAtcrating("S1");
        }
        if (member.atcrating === 3) {
            setAtcrating("S2");
        }
        if (member.atcrating === 4) {
            setAtcrating("S3");
        }
        if (member.atcrating === 5) {
            setAtcrating("C1");
        }
        if (member.atcrating === 6) {
            setAtcrating("C2");
        }
        if (member.atcrating === 7) {
            setAtcrating("C3");
        }
        if (member.atcrating === 8) {
            setAtcrating("I1");
        }
        if (member.atcrating === 10) {
            setAtcrating("I3");
        }
        if (!member.subdivision) {
            setSubdivision("")
        } else {
            setSubdivision(member.subdivision)
        }
    })


    return (
        <div style={{ marginLeft: 200 }}>
            <Grid container spacing={3}>
                {/* Chart */}
                <Grid item xs={12} md={8} lg={7}>
                    <Paper>
                        <Title >Member Profile</Title>

                        <div style={{ width: "50%", marginLeft: "10px" }}>
                            <TextField
                                disabled
                                id="outlined-disabled"
                                label="VATSIM ID"
                                value={member.vid}
                                margin="normal"
                                fullWidth
                            />

                        </div>
                        <div style={{ width: '50%', marginLeft: "10px" }}>
                            <TextField
                                disabled
                                id="outlined-disabled"
                                label="Name"
                                defaultValue={member.first_name + " " + member.last_name}
                                margin="normal"
                                fullWidth
                            />
                        </div>
                        <div style={{ width: '50%', marginLeft: "10px" }}>
                            <TextField
                                disabled
                                id="outlined-disabled"
                                label="Email"
                                defaultValue={member.email}
                                margin="normal"
                                fullWidth
                            />
                        </div>
                        <div style={{ width: '50%', marginLeft: "10px" }}>
                            <TextField
                                disabled
                                id="outlined-disabled"
                                label="Registration Date"
                                defaultValue={member.regdate}
                                margin="normal"
                                fullWidth
                            />
                        </div>
                        <div style={{ width: '50%', marginLeft: "10px" }}>
                            <TextField
                                disabled
                                id="outlined-disabled"
                                label="Controller Rating"
                                value={atcrating}
                                margin="normal"
                                fullWidth
                            />
                        </div>
                        <div style={{ width: '50%', marginLeft: "10px" }}>
                            <TextField
                                disabled
                                id="outlined-disabled"
                                label="Region - Division - Subdivision"
                                defaultValue={member.region + "-" + member.division + "-" + subdivision}
                                margin="normal"
                                fullWidth
                            />
                        </div>
                        <div style={{ width: '50%', marginLeft: "10px" }}>
                            <TextField
                                disabled
                                id="outlined-disabled"
                                label="Pilot Rating"
                                defaultValue={member.pilotrating}
                                margin="normal"
                                fullWidth
                            />
                        </div>
                    </Paper>

                </Grid>
                {/* Recent Deposits */}
                <Grid item xs={12} md={4} lg={3}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            height: 100,
                        }}
                    >
                        <Title >Total Flight Hours</Title>
                        <Typography component="p" variant="h4">
                            <div style={{ height: 371, width: '100%' }}>
                                {Math.round(member.flighthours)}
                            </div>
                        </Typography>
                    </Paper>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            height: 100,
                            marginTop: "10px"
                        }}
                    >
                        <Title >Total ATC Hours</Title>
                        <Typography component="p" variant="h4">
                            <div style={{ height: 371, width: '100%' }}>
                                {Math.round(member.atchours)}
                            </div>
                        </Typography>
                    </Paper>
                </Grid>

                {/* Recent Orders */}
            </Grid>
        </div>
    )

}