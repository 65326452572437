import React, { useState, useEffect } from 'react'
import Modal from '@mui/material/Modal';
import Title from '../../../../Title'
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSelector, useDispatch } from 'react-redux'
import { selectMember, selectToken } from '../../../../features/authSlice/authSlice'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import dayjs from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { FileUploader } from "react-drag-drop-files";
import Button from '@mui/material/Button';
import axios from 'axios'
import Grid from '@mui/material/Grid';
import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';


export default function ModifyEvent(props) {
    const { showModifyForm, setIsLoading, handleCloseModify, eventId } = props;
    const token = useSelector(selectToken);
    const [show, setShow] = useState();
    const [title, setTitle] = useState();
    const [description, setDescription] = useState();
    const [route, setRoute] = useState();
    const [start, setStart] = useState(null);
    const [end, setEnd] = useState(null);
    const [minStart, setMinStart] = useState();
    const [minEnd, setMinEnd] = useState();
    const [atcStart, setAtcStart] = useState(null);
    const [atcEnd, setAtcEnd] = useState(null);
    const [maxAtcStart, setMaxAtcStart] = useState()
    const [minAtcStart, setMinAtcStart] = useState();
    const [maxAtcEnd, setMaxAtcEnd] = useState();
    const [minAtcEnd, setMinAtcEnd] = useState();
    const [loadingButton, setLoadingButton] = useState(false);
    const [submitButton, setSubmitButton] = useState(true);
    const [logo, setLogo] = useState(null);
    const [positions, setPositions] = useState([]);
    const [positionsList, setPositionsList] = useState([]);
    const [duration, setDuration] = useState();
    const [initialValues, setInitialValues] = useState();
    const fileTypes = ["PNG"];
    const theme = useTheme();
    const [valid, setValid] = useState({ title: false, positions: false, start: false, end: false, atcStart: false, atcEnd: false, description: false, route: false, logo: false, duration: false });



    const style = {
        position: 'absolute',
        top: '54%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };


    function getStyles(name, personName, theme) {
        return {
            fontWeight:
                personName.indexOf(name) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }



    const handleSubmit = async (e) => {
        setSubmitButton(true);
        setLoadingButton(true);
        var data = new FormData();
        data.append('id', eventId);
        data.append('title', title);
        data.append('description', description);
        data.append('route', route);
        data.append('logo', logo);

        var config = {
            method: 'post',
            url: process.env.REACT_APP_VATIL_API + '/events/modifyevent',
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                setTimeout(() => { handleClose(); setIsLoading(true) }, 2500);
            })
            .catch(function (error) {
                console.log(error);
            });

    }
    const handleClose = () => {
        setTitle(null);
        setDescription(null);
        setRoute(null);
        setStart(null);
        setEnd(null);
        setMinStart(null);
        setMinEnd(null);
        setAtcStart(null);
        setAtcEnd(null);
        setMaxAtcStart(null);
        setMinAtcStart(null);
        setMaxAtcEnd(null);
        setMinAtcEnd(null);
        setLogo(null);
        setPositions([]);
        setSubmitButton(true);
        setLoadingButton(false);
        setValid({ ...valid, title: false, positions: false, start: false, end: false, atcStart: false, atcEnd: false, description: false, route: false, logo: false, duration: false })
        handleCloseModify();

    }

    const handleChange = (event) => {
        setPositions(event.target.value);
    }

    useEffect(() => {
        dayjs.extend(isSameOrAfter)
        dayjs.extend(isSameOrBefore)
        setShow(showModifyForm);

        if (positionsList.length < 1) {
            var config = {
                method: 'get',
                url: process.env.REACT_APP_VATIL_API + '/calendar/getpositions',
                headers: {
                    'Authorization': `Bearer ${token} `,
                }
            };

            axios(config)
                .then(function (response) {
                    setPositionsList(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        }

        if (!title) {
            var data = JSON.stringify({
                "id": eventId
            });

            var config = {
                method: 'post',
                url: process.env.REACT_APP_VATIL_API + '/events/geteventbyid',
                headers: {
                    'Authorization': `Bearer ${token} `,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(config)
                .then(function (response) {
                    const { title, description, route, start, end, minStart, minEnd, atcStart, atcEnd, maxAtcStart, minAtcStart, maxAtcEnd, minAtcEnd, positions, logo } = response.data;
                    setInitialValues(response.data);
                    const array = [];
                    setTitle(title);
                    setDescription(description);
                    setRoute(route);
                    setStart(start);
                    setEnd(end);
                    setMinStart(minStart);
                    setMinEnd(minEnd);
                    setAtcStart(atcStart);
                    setAtcEnd(atcEnd);
                    setMaxAtcStart(maxAtcStart);
                    setMinAtcStart(minAtcStart);
                    setMaxAtcEnd(maxAtcEnd);
                    setMinAtcEnd(minAtcEnd);
                    setLogo(logo);
                    for (let position of positionsList) {
                        let pos = positions.map(e => e.name).indexOf(position.name);
                        if (pos !== -1) {
                            array.push(position);
                        }
                    }
                    setPositions(array);
                })
                .catch(function (error) {
                    console.log(error);
                });

        }

        validate();

    }, [showModifyForm, title, description, route, logo])




    const validate = () => {
        if (valid.title || valid.description || valid.route || valid.logo) {
            setSubmitButton(false);
        } else {
            setSubmitButton(true);
        }
    }



    return (
        <Modal
            open={show}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ marginBottom: 5 }}
        >
            <Grid container spacing={2} sx={style}>
                <Title >Modify Event Form</Title>
                <Grid item lg={12}>
                    <TextField
                        id="outlined-disabled"
                        label="Title"
                        value={title}
                        InputLabelProps={{ shrink: title }}
                        fullWidth
                        onChange={(e) => {
                            if (e.target.value != initialValues.title) {
                                setTitle(e.target.value);
                                setValid({ ...valid, title: true })
                            } else {
                                setTitle(e.target.value);
                                setValid({ ...valid, title: false })
                            }
                        }}
                    />
                </Grid>
                <Grid item lg={12}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-multiple-chip-label">Positions</InputLabel>
                        <Select
                            labelId="demo-multiple-chip-label"
                            id="demo-multiple-chip"
                            multiple
                            disabled
                            value={positions}
                            onChange={handleChange}
                            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                            renderValue={(selected) => {
                                return (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((value) => (
                                            <Chip key={value.name} label={value.name} />
                                        ))}
                                    </Box>
                                )
                            }}
                            MenuProps={MenuProps}
                        >
                            {positionsList.map((position) => (
                                <MenuItem
                                    key={position._id}
                                    value={position}
                                    style={getStyles(position.name, positions, theme)}
                                >
                                    {position.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item lg={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            renderInput={(params) => <TextField {...params} fullWidth required />}
                            label="Start Time (UTC)"
                            disabled
                            onChange={(newValue) => {
                                if (newValue === null) {
                                    setStart(null);
                                    setEnd(null);
                                    setValid({ ...valid, start: false, end: false });
                                } else {
                                    let minEndDateTime = newValue.clone().add(90, 'minute');
                                    let minAtcStart = newValue.clone();
                                    setMinAtcStart(minAtcStart);
                                    setMinAtcEnd(minAtcStart)
                                    setMinEnd(minEndDateTime);
                                    setStart(newValue);
                                    setEnd(null);
                                    setAtcStart(null);
                                    setAtcEnd(null);
                                    setValid({ ...valid, start: true, end: false, atcStart: false, atcEnd: false });
                                }

                            }}
                            value={start}
                            ampm={false}
                            minDateTime={minStart}
                            disablePast
                            inputFormat="DD/MM/YYYY HH:mm"
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item lg={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            renderInput={(params) => <TextField {...params} fullWidth required />}
                            label="End Time (UTC)"
                            disabled
                            onChange={(newValue) => {
                                if (newValue === null) {
                                    setEnd(null);
                                    setValid({ ...valid, end: false });
                                } else {
                                    if (newValue.isSameOrAfter(minEnd)) {
                                        let maxAtcEndTime = newValue.clone();
                                        setEnd(newValue);
                                        setMaxAtcEnd(maxAtcEndTime)
                                        setMaxAtcStart(maxAtcEndTime)
                                        setAtcStart(null);
                                        setAtcEnd(null);
                                        setValid({ ...valid, end: true, atcStart: false, atcEnd: false });
                                    } else {
                                        setEnd(newValue);
                                        setValid({ ...valid, end: false, atcStart: false, atcEnd: false });
                                    }
                                }
                            }}
                            value={end}
                            minDateTime={minEnd}
                            ampm={false}
                            inputFormat="DD/MM/YYYY HH:mm"
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item lg={4}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            renderInput={(params) => <TextField {...params} fullWidth required />}
                            label="ATC Start Time (UTC)"
                            disabled
                            onChange={(newValue) => {
                                if (newValue === null) {
                                    setAtcStart(null);
                                    setAtcEnd(null);
                                    setValid({ ...valid, atcStart: false, atcEnd: false });
                                } else {
                                    setAtcStart(newValue);
                                } setValid({ ...valid, atcStart: true });

                            }}
                            value={atcStart}
                            ampm={false}
                            minDateTime={minAtcStart}
                            maxDateTime={maxAtcStart}
                            disablePast
                            inputFormat="DD/MM/YYYY HH:mm"
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item lg={4}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            renderInput={(params) => <TextField {...params} fullWidth required />}
                            label="ATC End Time (UTC)"
                            disabled
                            onChange={(newValue) => {
                                if (newValue === null) {
                                    setEnd(null);
                                    setValid({
                                        ...valid,
                                        atcEnd: false,
                                    })
                                } else {
                                    if (newValue.isSameOrBefore(maxAtcEnd)) {
                                        setAtcEnd(newValue);
                                        setValid({
                                            ...valid,
                                            atcEnd: true,
                                        })
                                    } else {
                                        setAtcEnd(newValue);
                                        setValid({
                                            ...valid,
                                            atcEnd: false,
                                        })
                                    }
                                }
                            }}
                            value={atcEnd}
                            maxDateTime={maxAtcEnd}
                            minDateTime={minAtcEnd}
                            ampm={false}
                            inputFormat="DD/MM/YYYY HH:mm"
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item lg={4}>
                    <TextField
                        id="outlined-disabled"
                        label="Shift Duration (minutes)"
                        type="number"
                        disabled
                        value={duration}
                        fullWidth
                        onChange={(e) => {
                            if (e.target.value) {
                                setDuration(e.target.value);
                                setValid({ ...valid, duration: true })
                            } else {
                                setTitle(e.target.value);
                                setValid({ ...valid, duration: false })
                            }
                        }}
                    />
                </Grid>
                <Grid item lg={12}>
                    <TextField
                        id="outlined-multiline-static"
                        label="Description"
                        multiline
                        fullWidth
                        InputLabelProps={{ shrink: description }}
                        value={description}
                        rows={6}
                        onChange={(e) => {
                            if (e.target.value != initialValues.description) {
                                setDescription(e.target.value);
                                setValid({ ...valid, description: true })
                            } else {
                                setDescription(e.target.value);
                                setValid({ ...valid, description: false })
                            }
                        }} />
                </Grid>
                <Grid item lg={12}>
                    <TextField
                        id="outlined-multiline-static"
                        label="Route"
                        multiline
                        fullWidth
                        InputLabelProps={{ shrink: route }}
                        value={route}
                        rows={4}
                        onChange={(e) => {
                            if (e.target.value != initialValues.route) {
                                setRoute(e.target.value);
                                setValid({ ...valid, route: true })
                            } else {
                                setRoute(e.target.value);
                                setValid({ ...valid, route: false })
                            }
                        }} />
                </Grid>
                <Grid item lg={12}>
                    <FileUploader handleChange={(file) => { setLogo(file); setValid({ ...valid, logo: true }); }} name='logo' types={fileTypes} label=" Upload or drop a logo right here" />
                </Grid>
                <Grid item lg={12}>
                    <LoadingButton
                        onClick={handleSubmit}
                        endIcon={<SendIcon />}
                        loading={loadingButton}
                        loadingPosition="end"
                        fullWidth
                        variant="contained"
                        disabled={submitButton}
                    >
                        Submit
                    </LoadingButton>
                </Grid>
            </Grid>
        </Modal >
    )
}