import React, { useState, useEffect } from 'react'
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import Title from '../../Title'
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSelector, useDispatch } from 'react-redux'
import { selectMember, selectToken } from '../../features/authSlice/authSlice'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import dayjs from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import axios from 'axios'

export default function ModifyRequest(props) {
    const { showModify, closeModifyForm, setIsLoading, event } = props;
    const member = useSelector(selectMember);
    const token = useSelector(selectToken);
    const [title, setTitle] = useState();
    const [pilots, setPilots] = useState();
    const [departure, setDeparture] = useState();
    const [destination, setDestination] = useState();
    const [start, setStart] = useState();
    const [end, setEnd] = useState();
    const [minEndDateTime, setEndMinDateTime] = useState();
    const [description, setDescription] = useState();
    const [loadingButton, setLoadingButton] = useState(false);
    const [submitButton, setSubmitButton] = useState(true);
    const [valid, setValid] = useState({ start: true, end: true, pilots: true });
    const [minStartDateTime, setMinStartDateTime] = useState()


    const handleSubmit = (e) => {
        e.preventDefault();
        setLoadingButton(true);
        var data = JSON.stringify({
            "vid": member.vid,
            "title": title,
            "pilots": pilots,
            "departureIcao": departure,
            "landingIcao": destination,
            "route": departure + "-" + destination,
            "description": description,
            "start": start,
            "end": end,
            "requestId": event
        });

        var config = {
            method: 'post',
            url: process.env.REACT_APP_VATIL_API + '/calendar/modifyrequest',
            headers: {
                'Authorization': `Bearer ${token} `,
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                setTimeout(() => {
                    closeModifyForm();
                    setIsLoading(true);
                }, 3000)
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    const handleClose = (e) => {
        setTitle(null);
        setPilots(null);
        setDeparture(null);
        setDestination(null);
        setStart(null);
        setEnd(null);
        setEndMinDateTime(null)
        setDescription(null);
        setSubmitButton(true);
        closeModifyForm();
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };


    useEffect(() => {

        if (!title) {
            var data = JSON.stringify({
                "requestId": event
            });

            var config = {
                method: 'post',
                url: process.env.REACT_APP_VATIL_API + '/calendar/getrequestbyid',
                headers: {
                    'Authorization': `Bearer ${token} `,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(config)
                .then(function (response) {
                    const { title, pilots, departureIcao, landingIcao, description } = response.data;
                    let start = dayjs(response.data.start);
                    let minEndDateTime = start.clone().add(90, 'minute');
                    let end = dayjs(response.data.end);
                    setTitle(title);
                    setPilots(pilots);
                    setDeparture(departureIcao);
                    setDestination(landingIcao);
                    setStart(start);
                    setEnd(end);
                    setEndMinDateTime(minEndDateTime)
                    setMinStartDateTime(dayjs().subtract(2, 'hour'))
                    setDescription(description);
                })
                .catch(function (error) {
                    console.log(error);
                });

        }
    }, [showModify])


    const validateForm = (object) => {
        if (object.pilots && object.start && object.end && title && departure && destination && description) {
            setSubmitButton(false)
            setValid(object);
        } else {
            setSubmitButton(true);
            setValid(object);

        }
    }



    return (
        <Modal
            open={showModify}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Stack component="form" noValidate spacing={3} sx={style}>
                <Title >Modify Request Form</Title>
                <TextField
                    id="outlined-disabled"
                    label="Request Title"
                    value={title}
                    InputLabelProps={{ shrink: title }}
                    margin="normal"
                    fullWidth
                    onChange={(e) => {
                        setTitle(e.target.value);
                        validateForm({ ...valid });
                    }}

                />
                <TextField
                    id="outlined-disabled"
                    label="Number Of Pilots Expected"
                    margin="normal"
                    type="number"
                    value={pilots}
                    InputLabelProps={{ shrink: pilots }}
                    InputProps={{ inputProps: { min: 15 } }}
                    autoFocus={true}
                    fullWidth
                    onChange={(e) => {
                        if (e.target.value < 15) {
                            setPilots(e.target.value);
                            setValid({
                                ...valid,
                                pilots: false,
                            })
                            validateForm({ ...valid, pilots: false });
                        } else {
                            setPilots(e.target.value);
                            setValid({
                                ...valid,
                                pilots: true,
                            })
                            validateForm({ ...valid, pilots: true });
                        }
                    }}

                />
                <TextField
                    id="outlined-disabled"
                    label="Departure ICAO"
                    margin="normal"
                    fullWidth
                    value={departure}
                    InputLabelProps={{ shrink: departure }}
                    onChange={(e) => {
                        setDeparture(e.target.value);
                        validateForm({ ...valid });
                    }}

                />
                <TextField
                    id="outlined-disabled"
                    label="Destination ICAO"
                    margin="normal"
                    value={destination}
                    fullWidth
                    InputLabelProps={{ shrink: destination }}
                    onChange={(e) => {
                        setDestination(e.target.value);
                        validateForm({ ...valid });
                    }}

                />

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                        renderInput={(params) => <TextField {...params} required />}
                        label="Start Time (UTC)"
                        onChange={(newValue) => {
                            if (newValue === null) {
                                setStart(null);
                                setEnd(null);
                                validateForm({ ...valid, start: false, end: false });

                            } else {
                                let minEndDateTime = newValue.clone().add(90, 'minute');
                                setEndMinDateTime(minEndDateTime);
                                setStart(newValue);
                                setEnd(null);
                                validateForm({ ...valid, start: true, end: false });
                            }
                        }}
                        value={start}
                        InputLabelProps={{ shrink: start }}
                        minDateTime={minStartDateTime}
                        disablePast
                        ampm={false}
                        inputFormat="DD/MM/YYYY HH:mm"

                    />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                        renderInput={(params) => <TextField {...params} required />}
                        label="End Time (UTC)"
                        onChange={(newValue) => {
                            if (newValue === null) {
                                setEnd(newValue);
                                validateForm({ ...valid, end: false });

                            } else {
                                if (newValue.isSameOrAfter(minEndDateTime)) {
                                    setEnd(newValue);
                                    validateForm({ ...valid, end: true });
                                } else {
                                    setEnd(newValue);
                                    validateForm({ ...valid, end: false });
                                }
                            }
                        }}
                        value={end}
                        InputLabelProps={{ shrink: end }}
                        minDateTime={minEndDateTime}
                        ampm={false}
                        inputFormat="DD/MM/YYYY HH:mm"

                    />
                </LocalizationProvider>
                <TextField
                    id="outlined-disabled"
                    label="Request Description"
                    margin="normal"
                    value={description}
                    InputLabelProps={{ shrink: description }}
                    fullWidth
                    required
                    multiline
                    rows={5}
                    onChange={(e) => {
                        setDescription(e.target.value);
                        validateForm({ ...valid });
                    }}

                />
                <LoadingButton
                    onClick={handleSubmit}
                    endIcon={<SendIcon />}
                    loading={loadingButton}
                    loadingPosition="end"
                    variant="contained"
                    disabled={submitButton}
                >
                    Update
                </LoadingButton>
            </Stack>
        </Modal >
    )
}