import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectMember, selectToken, selectValid } from '../../features/authSlice/authSlice'
import axios from 'axios';
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarExport, GridToolbarDensitySelector, GridToolbarQuickFilter } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Title from '../../Title'
import Button from '@mui/material/Button';
import FileUploadIcon from '@mui/icons-material/FileUpload';



function CustomToolbar() {
    const member = useSelector(selectMember);
    const subrole = member.subroles.find((element) => element === "ATC");
    if (member.role === 'Admin' && subrole !== -1) {
        return (
            <GridToolbarContainer>
                <GridToolbarQuickFilter />
                <Button variant="text" onClick={(e) => { }}><FileUploadIcon fontSize="small" />&nbsp; Update new material </Button>

            </GridToolbarContainer>
        );
    } else {
        return (
            <GridToolbarContainer>
                <GridToolbarQuickFilter />
            </GridToolbarContainer>
        );
    }

}

export default function AtcMaterials() {
    const member = useSelector(selectMember);
    const [isLoading, setIsLoading] = useState(true);
    const [materials, setMaterials] = useState(null);

    const columnscontrollers = [
        { field: 'name', headerName: 'Name', width: 300 },
        { field: 'link', headerName: 'Link', width: 200 },
        { field: 'description', headerName: 'Description', width: 600 },
    ];


    const loadingrows = [
        { vid: 'loading', name: 'loading', link: 'loading', description: 'loading', }
    ]

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={8} lg={13}>
                <Paper >
                    <Title >Learning Materials</Title>
                    <div style={{ height: 700, width: '100%' }}>
                        <DataGrid
                            getRowId={(row) => row.vid}
                            rows={materials ? materials : loadingrows}
                            columns={columnscontrollers}
                            pageSize={15}
                            rowsPerPageOptions={[15]}
                            loading={isLoading}
                            components={{ Toolbar: CustomToolbar }}
                        />
                    </div>
                </Paper>
            </Grid>
        </Grid >
    )
}