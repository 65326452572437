import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import EventImage from '../UpcomingEvent/EventImage';
import dayjs from 'dayjs'

const style = {
    position: 'absolute',
    top: '45%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function Event(props) {
    const { show, closeModal, event } = props;
    const handleClose = () => closeModal();

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={show}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={show}>
                    <Box sx={style}>
                        <EventImage url={event.logo} width={700} height={400} />
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            {event.title} - <br></br>
                            {dayjs(event.start).format('DD/MM/YYYY')}: {dayjs(event.start).format('HH:mm')}-{dayjs(event.end).format('HH:mm')} (UTC)
                        </Typography>
                        <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                            {event.description}
                        </Typography>
                    </Box>
                </Fade>
            </Modal>
        </div >
    );
}