import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSelector, useDispatch } from 'react-redux'
import { selectMember, selectToken } from '../../features/authSlice/authSlice'
import axios from 'axios'


export default function RemoveRequest(props) {
    const { showCancel, closeCancelForm, setIsLoading, eventId, title } = props;
    const [show, setShow] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);
    const token = useSelector(selectToken);

    const style = {
        position: 'absolute',
        top: '30%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const handlesubmit = (e) => {
        e.preventDefault();
        setLoadingButton(true);
        var data = JSON.stringify({
            "requestId": eventId,
        });

        var config = {
            method: 'post',
            url: process.env.REACT_APP_VATIL_API + '/calendar/removerequest',
            headers: {
                'Authorization': `Bearer ${token} `,
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                setTimeout(() => {
                    closeCancelForm();
                    setIsLoading(true);
                }, 3000)
            })
            .catch(function (error) {
                console.log(error);
            });

    }


    useEffect(() => {
        setShow(showCancel);
    })



    return (
        <Modal
            open={show}
            onClose={closeCancelForm}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Are you sure you want to cancel {title} ?
                </Typography>
                <Typography sx={{ marginTop: 1 }}>
                    <LoadingButton
                        onClick={handlesubmit}
                        loading={loadingButton}
                        loadingPosition="end"
                        variant="contained"
                        sx={{ backgroundColor: "#0a1a40" }}
                    >
                        Yes
                    </LoadingButton>
                    <Button onClick={closeCancelForm} color="error" sx={{ marginLeft: "10px" }} variant="contained">No</Button>
                </Typography>
            </Box>
        </Modal>
    );
}