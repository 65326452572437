import React, { useEffect, useState, Fragment } from 'react'
import ClockIcon from '@mui/icons-material/AccessTimeFilled'
import moment from 'moment';


export default function Time() {
    const [time, setTime] = useState();
    
    useEffect(() => {
        const interval = setInterval(() => {
            let utc = process.env.REACT_APP_UTC_TIME;
            let current = new Date();
            let converted = moment(current).utc();
            let day = converted.date();
            let month = converted.month() + 1;
            let year = converted.year();
            let hours = converted.hour();
            let minutes = converted.minute();
            let seconds = converted.second();

            if (day < 10) {
                day = "0" + converted.date();;
            }
            if (month < 10) {
                month = "0" + (converted.month() + 1);
            }
            if (hours < 10) {
                hours = "0" + (converted.hour());
            }
            if (minutes < 10) {
                minutes = "0" + converted.minute();
            }
            if (seconds < 10) {
                seconds = "0" + converted.second();
            }

            setTime(day + "/" + month + "/" + year + " " + hours + ":" + minutes + ":" + seconds + " " + "UTC");
        }, 1000);
        return () => clearInterval(interval);
    }, []);


    return (<div style={{ fontSize: "18px", fontWeight: "bold", fontFamily: "Roboto", color: "#142d54" }}><ClockIcon />&nbsp;{time}</div>);

}

