import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { selectToken } from '../../../../features/authSlice/authSlice';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function UpdateAtList(props) {
    const { showUpdate, closeUpdateForm, setIsLoading } = props;
    const [buttonLoading, setButtonLoading] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const token = useSelector(selectToken);
    // const [open, setOpen] = useState(false);
    // const handleOpen = () => setOpen(true);
    const handleClose = () => closeUpdateForm();
    const handleSubmit = () => {
        setButtonLoading(true);
        var config = {
            method: 'get',
            url: process.env.REACT_APP_VATIL_API + '/general/updatecontrollers',
            headers: {
                'Authorization': `Bearer ${token} `
            }
        };

        axios(config)
            .then(function (response) {
                setButtonLoading(false);
                setButtonDisabled(true);
                closeUpdateForm();
                setIsLoading(true);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    return (
        <Modal
            open={showUpdate}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Update Controllers List
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    You are about to update the division contollers list,
                    this process will add new controllers and delete those who left VATIL.<br></br>
                    It cans take up to 5 min, please don't close the window.<br></br>
                    Do you want to proceed? <br></br><br></br>
                    <LoadingButton color="primary" variant="contained" size="large" disabled={buttonDisabled} loading={buttonLoading} onClick={(e) => { handleSubmit(); }}>Yes</LoadingButton>&nbsp; &nbsp;
                    <Button color="primary" variant="outlined" size="large" disabled={buttonDisabled} onClick={(e) => { handleClose(); }}>No</Button>
                </Typography>
            </Box>
        </Modal>
    );
}
