import React, { useState, useEffect } from 'react'
import ModifyRequest from './ModifyRequest';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux'
import { selectMember, selectToken } from '../../../../features/authSlice/authSlice'
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import moment from 'moment'
import Stack from '@mui/material/Stack';


export default function AtcRequests() {
    const member = useSelector(selectMember);
    const token = useSelector(selectToken);
    const [requests, setRequests] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [showForm, setShowForm] = useState(false);
    const [showCancel, setShowCancel] = useState(false);
    const [showModify, setShowModify] = useState(false);
    const [eventId, setEventId] = useState();
    const [title, setTitle] = useState();



    const showNewRequestForm = () => {
        setShowForm(true);
    }

    const closeRequestForm = () => {
        setShowForm(false);
    }

    const closeCancelForm = () => {
        setShowCancel(false);
    }

    const closeModifyForm = () => {
        setShowModify(false);
    }

    const columnsrequests = [
        { field: 'title', headerName: 'Title', width: 200 },
        {
            field: 'start',
            headerName: 'Start',
            width: 250,
            renderCell: (cellValues) => {
                let date = moment(cellValues.row.start).format('DD/MM/yyyy HH:mm');
                return (
                    date
                )
            }
        },
        {
            field: 'end',
            headerName: 'End',
            width: 250,
            renderCell: (cellValues) => {
                let date = moment(cellValues.row.end).format('DD/MM/yyyy HH:mm');
                return (
                    date
                )
            }
        },
        {
            field: "actions",
            width: 220,
            headerName: 'Actions',
            renderCell: (cellValues) => {
                if (cellValues.row.managerapproval === false) {
                    return (
                        <div>
                            <Button
                                sx={{ backgroundColor: "#0a1a40" }}
                                variant="contained"
                                onClick={async (e) => {
                                    e.preventDefault();
                                    setEventId(cellValues.row._id);
                                    setShowModify(true);
                                    // handleClick(event, cellValues);
                                }}
                            >
                                View
                            </Button>
                        </div>
                    );
                }
            }
        }
    ];


    const loadingrows = [{ id: "1" }]





    useEffect(() => {
        var config = {
            method: 'get',
            url: process.env.REACT_APP_VATIL_API + '/calendar/getrequests',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        axios(config)
            .then(function (response) {
                setTimeout(() => {
                    setRequests(response.data)
                    setIsLoading(false);
                }, 2500)
            })
            .catch(function (error) {
                console.log(error);
            });


    }, [isLoading]);



    if (isLoading) {
        return (
            <div style={{ height: 600, width: '100%' }}>
                <DataGrid
                    rows={loadingrows}
                    loading={isLoading}
                    columns={columnsrequests}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                />
            </div>

        )
    } else {
        if (requests.length > 0) {
            return (
                <div style={{ height: 600, width: '100%', }}>
                    <DataGrid
                        getRowId={(row) => row.title}
                        rows={requests}
                        columns={columnsrequests}
                        pageSize={15}
                        rowsPerPageOptions={[15]}
                        loading={isLoading}
                    />
                    <ModifyRequest showModify={showModify} closeModifyForm={closeModifyForm} setIsLoading={setIsLoading} event={eventId} />
                </div>
            )
        } else {
            return (
                <div style={{ height: 600, width: '100%', }}>
                    <DataGrid
                        getRowId={(row) => row.title}
                        rows={requests}
                        columns={columnsrequests}
                        pageSize={15}
                        rowsPerPageOptions={[15]}
                        loading={isLoading}
                        components={{
                            NoRowsOverlay: () => (
                                <Stack height="100%" alignItems="center" justifyContent="center">
                                    No Requests Found
                                </Stack>
                            ),
                        }}
                    />
                    <ModifyRequest showModify={showModify} closeModifyForm={closeModifyForm} setIsLoading={setIsLoading} event={eventId} />
                </div>
            )
        }
    }

}

