import React from "react"
import { Navigate, Outlet } from "react-router-dom"
import { selectMember, selectToken, selectValid } from '../../../../features/authSlice/authSlice'
import { useSelector, useDispatch } from 'react-redux'

const useAuth = () => {
    let member = useSelector(selectMember)
    let valid = useSelector(selectValid);


    if (valid) {
        return ({ auth: true, rating: member.atcrating, division: member.division })
    } else {
        return ({ auth: false, role: null })
    }
}

const AtcProtectedRoutes = () => {
    const { auth, rating, division } = useAuth();

    if (auth) {
        if (rating > 1 && division === "IL") {
            return (<Outlet />)
        } else {
            <Navigate to="/denied" />
        }
    } else {
        <Navigate to="/login" />
    }
}


export default AtcProtectedRoutes
