import AddIcon from '@mui/icons-material/Add';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { DataGrid, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Title from '../../../../Title';
import { selectToken } from '../../../../features/authSlice/authSlice';
import ModifyActive from './ModifyActive';
import UpdateAtList from './UpdateAtcList';
import VisitorController from './VisitorController';

export default function AtcRoster() {
    const token = useSelector(selectToken);
    const [controllers, setControllers] = useState();
    const [atc, setAtc] = useState();;
    const [isLoading, setIsLoading] = useState(true);
    const [showModify, setShowModify] = useState(false);
    const [showUpdate, setShowUpdate] = useState(false);
    const [showVisitor, setShowVisitor] = useState(false);

    const columnscontrollers = [
        { field: 'vid', headerName: 'VATSIM ID', width: 150 },
        { field: 'name', headerName: 'Name', width: 270 },
        { field: 'rating', headerName: 'Rating', width: 220 },
        { field: 'qualified', headerName: 'Active?', width: 150 },
        { field: 'positions', headerName: 'Positions', width: 350 },
        // { field: 'last3monthshours', headerName: 'Hours in last 3 months', width: 200 },
    ];

    const CustomToolbar = () => {
        return (
            <GridToolbarContainer>
                {/* <GridToolbarColumnsButton /> */}
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
                <Button variant="text" onClick={(e) => { setShowUpdate(true) }}><SystemUpdateAltIcon fontSize="small" />&nbsp; Get updated controllers list </Button>
                <Button variant="text" onClick={(e) => { setShowVisitor(true) }}><AddIcon fontSize="small" />&nbsp; Add visitor controller </Button>
            </GridToolbarContainer>
        )
    }

    const loadingrows = [
        { vid: 'loading', name: 'loading', rating: 'loading', qualified: 'loading', }
    ]

    const closeModifyForm = () => {
        setShowModify(false);
    }

    const closeUpdateForm = () => {
        setShowUpdate(false);
    }

    const closeVisitor = () => {
        setShowVisitor(false);
    }

    useEffect(() => {
        var config = {
            method: 'get',
            url: process.env.REACT_APP_VATIL_API + '/general/getcontrollers',
            headers: {
                'Authorization': `Bearer ${token} `
            }
        };

        axios(config)
            .then(function (response) {
                const sortedData = response.data.sort((a, b) => b.last3monthshours - a.last3monthshours);
                setControllers(sortedData);
                setIsLoading(false);
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [isLoading])



    if (isLoading) {
        return (
            <Grid container spacing={3}>
                <Grid item xs={12} md={8} lg={13}>
                    <Paper >
                        <Title >ATC Roster</Title>
                        <div style={{ height: 600, width: '100%' }}>
                            <DataGrid
                                getRowId={(row) => row.vid}
                                rows={loadingrows}
                                columns={columnscontrollers}
                                pageSize={5}
                                rowsPerPageOptions={[5]}
                                loading={isLoading}
                            />
                        </div>
                    </Paper>
                </Grid>
            </Grid >
        )
    } else {
        return (
            <Grid container spacing={3}>
                <Grid item xs={12} md={8} lg={13}>
                    <Paper >
                        <Title >ATC Roster</Title>
                        <div style={{ height: 700, width: '100%' }}>
                            <DataGrid
                                getRowId={(row) => row.vid}
                                rows={controllers}
                                columns={columnscontrollers}
                                pageSize={15}
                                rowsPerPageOptions={[15]}
                                loading={isLoading}
                                components={{ Toolbar: CustomToolbar }}
                                onRowClick={(e) => { setAtc(e.row); setShowModify(true) }}
                            />
                        </div>
                    </Paper>
                </Grid>
                <ModifyActive showModify={showModify} closeModifyForm={closeModifyForm} setIsLoading={setIsLoading} atc={atc} />
                <UpdateAtList showUpdate={showUpdate} closeUpdateForm={closeUpdateForm} setIsLoading={setIsLoading} />
                <VisitorController showVisitor={showVisitor} closeVisitor={closeVisitor} setIsLoading={setIsLoading} />
            </Grid >
        )
    }

}


