import React, { useState, useEffect } from 'react'
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import Title from '../../Title'
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSelector, useDispatch } from 'react-redux'
import { selectMember, selectToken } from '../../features/authSlice/authSlice'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import axios from 'axios'
import Button from '@mui/material/Button'

export default function ModifyRequest(props) {
    const { showModify, closeModifyForm, setIsLoading, booking } = props;
    const member = useSelector(selectMember);
    const token = useSelector(selectToken);
    const [callsign, setCallsign] = useState(null);
    const [start, setStart] = useState(null);
    const [end, setEnd] = useState(null);
    const [positions, setPositions] = useState([]);
    const [minEndDateTime, setEndMinDateTime] = useState();
    const [minStartDateTime, setMinStartDateTime] = useState()
    const [loadingButton, setLoadingButton] = useState(false);
    const [submitButton, setSubmitButton] = useState(true);
    const [loadingForm, setIsLoadingForm] = useState(true);
    const [valid, setValid] = useState({ callsign: true, start: true, end: true });




    const handleClose = () => {
        let start = dayjs(booking.start);
        let minEndDateTime = start.clone().add(90, 'minute');
        let end = dayjs(booking.end);
        setCallsign(booking.callsign);
        setStart(start);
        setEndMinDateTime(minEndDateTime)
        setEnd(end);
        setSubmitButton(true);
        setValid({
            start: true,
            end: true,
            callsign: true,
        })
        closeModifyForm();
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        setLoadingButton(true);
        var data = JSON.stringify({
            "vid": member.vid,
            "callsign": callsign,
            "start": start,
            "end": end,
            "id": booking.id,
            "type": "booking",
        });

        var config = {
            method: 'post',
            url: process.env.REACT_APP_VATIL_API + '/calendar/modifyatcbooking',
            headers: {
                'Authorization': `Bearer ${token} `,
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                handleClose();
                setIsLoading(true);
            })
            .catch(function (error) {
                console.log(error);
            });

    }



    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };


    useEffect(() => {
        dayjs.extend(isSameOrAfter)
        var data = JSON.stringify({
            "rating": member.atcrating
        });

        var config = {
            method: 'post',
            url: process.env.REACT_APP_VATIL_API + '/calendar/getpositions',
            headers: {
                'Authorization': `Bearer ${token} `,
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                let start = dayjs(booking.start);
                let minEndDateTime = start.clone().add(90, 'minute');
                let end = dayjs(booking.end);
                setPositions(response.data);
                setCallsign(booking.callsign);
                setStart(start);
                setEndMinDateTime(minEndDateTime)
                setMinStartDateTime(dayjs().subtract(2, 'hour'))
                setEnd(end);
            })
            .catch(function (error) {
                console.log(error);
            });


    }, [booking])

    const validateForm = (object) => {
        if (object.callsign && object.start && object.end) {
            setSubmitButton(false)
            setValid(object);
        } else {
            setSubmitButton(true);
            setValid(object);

        }
    }



    return (
        <Modal
            open={showModify}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Stack component="form" noValidate spacing={3} sx={style}>
                <Title >Modify ATC Booking</Title>
                <h5>Note: ATC booking needs to be at least 90 min.</h5>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Position</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={callsign}
                        label="Position"
                        onChange={async (newValue) => {
                            setCallsign(newValue.target.value);
                            validateForm({ ...valid, callsign: true })
                        }}
                    >
                        {positions.map((pos, i) => { return <MenuItem key={i} value={pos.name}>{pos.name}</MenuItem> })}
                    </Select>
                </FormControl>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                        label="Start Time (UTC)"
                        value={start}
                        onChange={(newValue) => {
                            if (newValue === null) {
                                setStart(null);
                                setEnd(null);
                                validateForm({ ...valid, start: false, end: false });

                            } else {
                                let minEndDateTime = newValue.clone().add(90, 'minute');
                                setEndMinDateTime(minEndDateTime);
                                setStart(newValue);
                                setEnd(null);
                                validateForm({ ...valid, start: true, end: false });
                            }

                        }}
                        disablePast
                        ampm={false}
                        minDateTime={minStartDateTime}
                        inputFormat="DD/MM/YYYY HH:mm"
                        renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                        label="End Time (UTC)"
                        value={end}
                        onChange={(newValue) => {
                            if (newValue === null) {
                                setEnd(newValue);
                                validateForm({ ...valid, end: false });

                            } else {
                                if (newValue.isSameOrAfter(minEndDateTime)) {
                                    setEnd(newValue);
                                    validateForm({ ...valid, end: true });
                                } else {
                                    setEnd(newValue);
                                    validateForm({ ...valid, end: false });
                                }
                            }
                        }}
                        minDateTime={minEndDateTime}
                        ampm={false}
                        inputFormat="DD/MM/YYYY HH:mm"
                        renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
                <LoadingButton
                    onClick={handleSubmit}
                    endIcon={<SendIcon />}
                    loading={loadingButton}
                    loadingPosition="end"
                    variant="contained"
                    disabled={submitButton}
                >
                    Submit
                </LoadingButton>
            </Stack>
        </Modal >
    )

}