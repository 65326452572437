import React, { useState, useEffect } from 'react'
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import Title from '../../../../Title'
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import { useTheme } from '@mui/material/styles';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSelector, useDispatch } from 'react-redux'
import { selectMember, selectToken } from '../../../../features/authSlice/authSlice'
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import OutlinedInput from '@mui/material/OutlinedInput';
import Button from '@mui/material/Button'
import axios from 'axios'


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}


export default function AssignRole(props) {
    const { showForm, setShowForm, setIsLoading } = props;
    const token = useSelector(selectToken);
    const theme = useTheme();
    const [vid, setVid] = useState(null);
    const [fullName, setFullName] = useState(null);
    const [role, setRole] = useState(null);
    const [subRole, setSubRole] = useState([]);
    const [roles, setRoles] = useState(['Member', 'Admin']);
    const [subroles, setSubroles] = useState(['Director', 'ATC', 'Events']);
    const [submitButton, setSubmitButton] = useState(true);
    const [loadingButton, setLoadingButton] = useState(false);
    const [showRequest, setShowRequest] = useState(false);
    const [disabledFields, setDisabledFields] = useState(false);
    const [roleShrink, setRoleShrink] = useState(false);
    const [error, setError] = useState(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };


    const handleSubmit = () => {
        setLoadingButton(true);
        var data = JSON.stringify({
            "vid": vid,
            "role": role,
            "subrole": subRole
        });

        var config = {
            method: 'post',
            url: process.env.REACT_APP_VATIL_API + '/general/modifyroles',
            headers: {
                'Authorization': `Bearer ${token} `,
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                setTimeout(() => { handleClose(); setIsLoading(true) }, 3000);
            })
            .catch(function (error) {
                console.log(error);
            });


    }
    const handleClose = () => {
        setVid(null);
        setShowForm(false)
    }
    const handleChange = (e) => {
        setSubRole(e.target.value);
    }




    useEffect(() => {
        setShowRequest(showForm);
        var data = JSON.stringify({
            "vid": vid
        });

        var config = {
            method: 'post',
            url: process.env.REACT_APP_VATIL_API + '/general/getmemberbyvid',
            headers: {
                'Authorization': `Bearer ${token} `,
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                if (response.data.first_name != null) {
                    setFullName(response.data.first_name + " " + response.data.last_name)
                    setRoleShrink(true);
                    setRole(response.data.role)
                    setSubRole(response.data.subroles)
                    setSubmitButton(false);
                    setError(false);
                } else {
                    setFullName("")
                    setRoleShrink(false);
                    setRole(null)
                    setSubRole([])
                    setSubmitButton(true);
                    setError(true);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [showForm, vid])

    return (
        <Modal
            open={showRequest}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ marginBottom: 20 }}
        >
            <Stack component="form" noValidate spacing={3} sx={style}>
                <Title >Assign Role Form</Title>
                <TextField
                    id="outlined-disabled"
                    label="VATSIM ID"
                    value={vid}
                    inputProps={{ type: 'number' }}
                    margin="normal"
                    fullWidth
                    onChange={(e) => {
                        setVid(e.target.value);
                    }}
                    error={error}
                    helperText="No member found."
                />
                <TextField
                    id="outlined-disabled"
                    label="Full Name"
                    value={fullName}
                    InputLabelProps={{ shrink: fullName }}
                    margin="normal"
                    fullWidth
                    onChange={(e) => {
                        setVid(e.target.value);
                    }}
                    disabled={true}
                />
                <FormControl fullWidth>
                    <InputLabel shrink={roleShrink}>Role</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={role}
                        label="Role"
                        input={<OutlinedInput notched={roleShrink} label="Role" />}
                        disabled={disabledFields}
                        onChange={(e) => {
                            setRole(e.target.value);
                        }}
                    >
                        {roles.map((pos, i) => { return <MenuItem key={i} value={pos}>{pos}</MenuItem> })}
                    </Select>
                </FormControl>
                <FormControl sx={{ m: 1, width: 300 }}>
                    <InputLabel id="demo-multiple-chip-label">Sub-Roles</InputLabel>
                    <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple
                        value={subRole}
                        disabled={disabledFields}
                        onChange={handleChange}
                        input={<OutlinedInput id="select-multiple-chip" label="Sub-Roles" />}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                    <Chip key={value} label={value} />
                                ))}
                            </Box>
                        )}
                        MenuProps={MenuProps}
                    >
                        {subroles.map((role) => (
                            <MenuItem
                                key={role}
                                value={role}
                                style={getStyles(role, subroles, theme)}
                            >
                                {role}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <LoadingButton
                    onClick={handleSubmit}
                    endIcon={<SendIcon />}
                    loading={loadingButton}
                    loadingPosition="end"
                    variant="contained"
                    disabled={submitButton}
                >
                    Submit
                </LoadingButton>
                {/* <Button variant='contained' onClick={() => { fetchMember() }}>Click</Button> */}
            </Stack>
        </Modal >
    )
}