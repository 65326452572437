import React, { useEffect, useState } from "react"
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TableFlights from '../../../TableFlights/TableFlights';
import Scenery from '../../Airport/Scenery/Scenery'
import Details from '../../Airport/Details/Details';
import Charts from '../../Airport/Charts/Charts'
import axios from 'axios';
import { selectMember, selectToken, selectValid } from '../../../features/authSlice/authSlice'
import { useSelector, useDispatch } from 'react-redux'
import AdminDivisionDirector from '../../Admin/AdminDivisionDirector/AdminDivisionDirector'
import AdminEvents from '../AdminEvents/AdminEvents'
import AdminAtc from '../AdminAtc/AdminAtc';



function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

export default function AdminMain(props) {
    const { icao } = props;
    const theme = useTheme();
    const [value, setValue] = useState(0);
    const token = useSelector(selectToken);
    const member = useSelector(selectMember);
    const [isLoading, setIsLoading] = useState(true)
    const [airport, setAirport] = useState();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };


    useEffect(() => {

    }, [])



    return (
        <Box sx={{ bgcolor: 'background.paper' }}>
            <AppBar position="static">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="inherit"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                    style={{ backgroundColor: "#142d54" }}
                >
                    <Tab label="Division Director" {...a11yProps(0)} />
                    <Tab label="ATC Training" {...a11yProps(1)} />
                    <Tab label="Events" {...a11yProps(1)} />
                </Tabs>
            </AppBar>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
            >
                {member.subroles.indexOf('Director') === -1 ? null :
                    <TabPanel value={value} index={0} dir={theme.direction} key={Math.random()}>
                        <AdminDivisionDirector />
                    </TabPanel>
                }

                {member.subroles.indexOf('ATC') === -1 ? null :
                    <TabPanel value={value} index={1} dir={theme.direction} key={Math.random()}>
                        <AdminAtc />
                    </TabPanel>
                }

                {member.subroles.indexOf('Events') === -1 ? null :
                    <TabPanel value={value} index={2} dir={theme.direction} key={Math.random()}>
                        <AdminEvents />
                    </TabPanel>
                }
            </SwipeableViews>
        </Box>
    );
}
