import React, { useState, useEffect } from 'react'
import { selectMember, selectToken, selectValid } from '../../../features/authSlice/authSlice'
import { useSelector, useDispatch } from 'react-redux'
import { BrowserRouter, Route, Link } from 'react-router-dom'
import Table from '@mui/material/Table';
import Button from '@mui/material/Button'
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from '../../../Title'
import axios from 'axios'
import dayjs from 'dayjs'
import Event from './Event'
import Request from './Request'
import Atc from './Atc'

export default function UpcomingActivity() {
    const member = useSelector(selectMember);
    const token = useSelector(selectToken);
    const [data, setData] = useState([]);
    const [option, setOption] = useState({});
    const [showRequest, setShowRequest] = useState(false);
    const [showEvent, setShowEvent] = useState(false);
    const [showAtc, setShowAtc] = useState(false);


    useEffect(() => {
        var config = {
            method: 'get',
            url: process.env.REACT_APP_VATIL_API + '/calendar/getactivity',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        axios(config)
            .then(function (response) {
                setData(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });

    }, [])


    return (
        <React.Fragment>
            <Title>Upcoming Activity (ATC & Events)</Title>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Date </TableCell>
                        <TableCell>Type / Name</TableCell>
                        <TableCell>From (UTC)</TableCell>
                        <TableCell>To (UTC)</TableCell>
                        <TableCell >Details</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map(element => {
                        if (element.type === "booking") {
                            return (
                                <TableRow key={element.id}>
                                    <TableCell>{dayjs(element.start).format('DD/MM/YYYY')}</TableCell>
                                    <TableCell>ATC Online</TableCell>
                                    <TableCell>{dayjs(element.start).format('HH:mm')}</TableCell>
                                    <TableCell>{dayjs(element.end).format('HH:mm')}</TableCell>
                                    <TableCell>
                                        <Button color='primary' onClick={() => { setOption(element); setShowAtc(true) }}>
                                            View
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            )
                        }
                        if (element.type === "exam") {
                            return (
                                <TableRow key={element.id}>
                                    <TableCell>{dayjs(element.start).format('DD/MM/YYYY')}</TableCell>
                                    <TableCell>ATC Exam</TableCell>
                                    <TableCell>{dayjs(element.start).format('HH:mm')}</TableCell>
                                    <TableCell>{dayjs(element.end).format('HH:mm')}</TableCell>
                                    <TableCell>
                                        <Button color='primary' onClick={() => { setOption(element); setShowAtc(true) }}>
                                            View
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            )
                        }
                        if (element.type === "Division") {
                            return (
                                <TableRow key={element._id}>
                                    <TableCell>{dayjs(element.start).format('DD/MM/YYYY')}</TableCell>
                                    <TableCell>{element.title}</TableCell>
                                    <TableCell>{dayjs(element.start).format('HH:mm')}</TableCell>
                                    <TableCell>{dayjs(element.end).format('HH:mm')}</TableCell>
                                    <TableCell>
                                        <Button color='primary' onClick={() => { setOption(element); setShowEvent(true) }}>
                                            View
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            )
                        }
                        if (element.type === "Request") {
                            return (
                                <TableRow key={element._id}>
                                    <TableCell>{dayjs(element.start).format('DD/MM/YYYY')}</TableCell>
                                    <TableCell>Group Flight</TableCell>
                                    <TableCell>{dayjs(element.start).format('HH:mm')}</TableCell>
                                    <TableCell>{dayjs(element.end).format('HH:mm')}</TableCell>
                                    <TableCell>
                                        <Button color='primary' onClick={() => { setOption(element); setShowRequest(true) }}>
                                            View
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            )
                        }
                    })}
                </TableBody>
            </Table>
            <Link to="/event" style={{ color: 'black', marginTop: "15px" }}>Upcoming Division Event</Link>
            <Event show={showEvent} closeModal={() => { setShowEvent(false) }} event={option} />
            <Request show={showRequest} closeModal={() => { setShowRequest(false) }} event={option} />
            <Atc show={showAtc} closeModal={() => { setShowAtc(false) }} event={option} />
        </React.Fragment>
    );
}
