import React from "react"
import { Navigate, Outlet } from "react-router-dom"
import { selectMember, selectToken, selectValid } from '../../../../features/authSlice/authSlice'
import { useSelector, useDispatch } from 'react-redux'

const useAuth = () => {
    let member = useSelector(selectMember)
    let valid = useSelector(selectValid);


    if (valid) {
        return ({ auth: true, role: member.role })
    } else {
        return ({ auth: false, role: null })
    }
}

const ProtectedRoutes = (props) => {
    const { roleRequired } = props;
    const { auth, role } = useAuth();

    if (roleRequired) {
        return auth ? (roleRequired === role ? (<Outlet />) : (<Navigate to="/denied" />)) : (<Navigate to="/login" />)
    } else {
        return auth ? <Outlet /> : <Navigate to="/login" />
    }

}


export default ProtectedRoutes
