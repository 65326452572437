import { Paper, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectMember } from '../../features/authSlice/authSlice';
import Title from '../../Title';
import { Link } from "react-router-dom";

export default function NewAtc(props) {
    const member = useSelector(selectMember);

    function replaceWithBr(string) {
        return string.replace(/\n/g, "<br />")
    }

    if (member.division !== "IL") {
        return (
            <Paper elevation={3} sx={{ height: "100px" }}>
                <p style={{ padding: "10px" }}>
                    <b>It seems you are not a VATSIM Israel member, if you are interesed to become a visiting contoller please use the <Link to={"/contact"}>contact form.</Link></b>
                </p>
            </Paper>
        )
    } else {
        return (
            <Paper elevation={3}>
                <Typography component="h2" variant="h6" color="#142d54" gutterBottom sx={{ padding: "5px" }}>
                    Become An Air Traffic Controller in VATIL
                </Typography>
                <p style={{ padding: "10px" }} dangerouslySetInnerHTML={{
                    __html: replaceWithBr(`<b>Step 1:</b> Read the training material ("Basic Knowledge" and "LLBG GND" folders) - <a target="_blank" href="https://drive.google.com/drive/folders/1AOG_POjz3JHSLdUrAyitZVC2Ckq7-jAG?usp=sharing">Click Here</a>
    
                    <b>Step 2:</b> Take the LLBG Ground Written Exam -  <a target="_blank" href="https://forms.gle/FPXUrkhG5cChW7hd6">Click Here</a>
    
                    <b>Step 3:</b> If you passed the exam <b>(80%)</b> you will be added to the waiting list.
                    If you failed the exam, it will be opened again after 72 hours.
    
                    <b><u>LLBG Ground Training Process</u></b>
                    1.	You will be contacted and assigned a mentor.
                    2.	SweatBox sessions (3-4 mentoring sessions on the VATSIM SweatBox simulation server).
                    3.	Online sessions (1-2 mentoring sessions).
                    4.	Practical exam on the SweatBox server.

                    <b><u>What we expect from you:</u></b>
* A certain amount of availability so our mentors can schedule with you. Each one to one training session is between 1-2 hours on Discord (voice communication program)
* To review your mentor’s notes and study the training theory between sessions to ensure that you are prepared for each mentoring session.
* To turn up to your mentoring sessions on time and ready to learn. 
* To have fun! 

Our mentors are here to answer any questions you have about the training theory. Training usually takes around 1 month.
    
                    <b><u>Further Training</u></b>
                    In order to progress to LLBG Tower, request this using the contact form.
                    Preferred requirements – 75 hours on LLBG Ground.
                    
                    <b>Good Luck!</b>`)
                }}>


                </p>
            </Paper>
        )
    }

}




