import { createSlice } from "@reduxjs/toolkit";


export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        member: null,
        token: null,
        isValid: false,
    },
    reducers: {
        setAuth: (state, action) => {
            const { member, token, valid } = action.payload;
            state.member = member;
            state.token = token;
            state.isValid = valid;
        }
    }
})

export const { setAuth } = authSlice.actions;
export const selectValid = (state) => state.auth.isValid;
export const selectMember = (state) => state.auth.member;
export const selectToken = (state) => state.auth.token;
export default authSlice.reducer;