import React, { useState, useEffect } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import { GridToolbarContainer, } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import NewEvent from './NewEvent';
import axios from 'axios'
import { useSelector, useDispatch } from 'react-redux'
import { selectMember, selectToken } from '../../../../features/authSlice/authSlice'
import Stack from '@mui/material/Stack';
import dayjs from 'dayjs'
import RemoveEvent from './RemoveEvent';
import ModifyEvent from './ModifyEvent';



export default function Events(props) {
    const [showNewEventForm, setShowNewEventForm] = useState(false);
    const [showRemoveEvent, setShowRemoveEvent] = useState(false);
    const [showModifyForm, setShowModifyForm] = useState(false);
    const token = useSelector(selectToken);
    const [rows, setRows] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [eventId, setEventId] = useState();
    const [title, setTitle] = useState();


    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <Button
                    variant="text"
                    onClick={async (e) => {
                        e.preventDefault();
                        setShowNewEventForm(true);
                    }}
                >
                    New Event
                </Button>
            </GridToolbarContainer >
        );
    }

    const columnsevents = [
        { field: 'title', headerName: 'Title', width: 200 },
        {
            field: 'start',
            headerName: 'Start',
            width: 250,
            renderCell: (cellvalues) => {
                if (isLoading) {
                    return ("")
                } else {
                    return (
                        dayjs(cellvalues.row.start).format('DD/MM/YYYY HH:mm')
                    )
                }
            }
        },
        {
            field: 'end',
            headerName: 'End',
            width: 250,
            renderCell: (cellvalues) => {
                if (isLoading) {
                    return ("")
                } else {
                    return (
                        dayjs(cellvalues.row.end).format('DD/MM/YYYY HH:mm')
                    )
                }
            }
        },
        {
            field: 'atcStart',
            headerName: 'ATC',
            width: 300,
            renderCell: (cellValues) => {
                if (isLoading) {
                    return ("")
                } else {
                    return (
                        `  ${dayjs(cellValues.row.atcStart).format('HH:mm')} -  ${dayjs(cellValues.row.atcEnd).format('HH:mm')}`
                    )
                }
            }
        },
        {
            field: "actions",
            width: 220,
            headerName: 'Actions',
            renderCell: (cellValues) => {
                if (isLoading) {
                    return ("")
                } else {
                    return (
                        <div>
                            <Button
                                sx={{ backgroundColor: "#0a1a40" }}
                                variant="contained"
                                onClick={async (e) => {
                                    setEventId(cellValues.row._id);
                                    handleShowModify();
                                }}
                            >
                                Modify
                            </Button>
                            <Button
                                sx={{ marginLeft: 1 }}
                                variant="contained"
                                color="error"
                                onClick={(e) => {
                                    setEventId(cellValues.row._id);
                                    setTitle(cellValues.row.title);
                                    handleShowRemove();

                                }}
                            >
                                Remove
                            </Button>
                        </div>
                    );
                }

            }
        }
    ];
    const loadingrows = [{ id: "1" }]


    const handleShowRemove = (e) => {
        setShowRemoveEvent(true);
    }

    const handleCloseRemove = () => {
        setShowRemoveEvent(false);
    }

    const handleShowModify = (e) => {
        setShowModifyForm(true);
    }


    const handleCloseModify = (e) => {
        setShowModifyForm(false);
    }

    const closeNewEventForm = () => {
        setShowNewEventForm(false);
    }



    useEffect(() => {
        var config = {
            method: 'get',
            url: process.env.REACT_APP_VATIL_API + '/events/getallevents',
            headers: {
                'Authorization': `Bearer ${token} `,
            }
        };

        axios(config)
            .then(function (response) {
                setTimeout(() => { setRows(response.data); setIsLoading(false) }, 2500)
            })
            .catch(function (error) {
                console.log(error);
            });

    }, [isLoading])


    if (isLoading) {
        return (
            <div style={{ height: 600, width: '100%' }}>
                <DataGrid
                    rows={loadingrows}
                    columns={columnsevents}
                    loading={isLoading}
                    pageSize={8}
                    rowsPerPageOptions={[5]}
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                />

            </div>
        )
    } else {
        if (rows.length > 0) {
            return (
                <div style={{ height: 600, width: '100%' }}>
                    <DataGrid
                        getRowId={(row) => row.title}
                        rows={rows}
                        columns={columnsevents}
                        pageSize={8}
                        rowsPerPageOptions={[8]}
                        // loading={isLoading}
                        components={{
                            Toolbar: CustomToolbar,
                        }}
                    />
                    <NewEvent showForm={showNewEventForm} closeNewEventForm={closeNewEventForm} setIsLoading={setIsLoading} />
                    <RemoveEvent showRemoveEvent={showRemoveEvent} handleCloseRemove={handleCloseRemove} setIsLoading={setIsLoading} eventId={eventId} title={title} />
                    <ModifyEvent showModifyForm={showModifyForm} handleCloseModify={handleCloseModify} setIsLoading={setIsLoading} eventId={eventId} />
                </div>
            )
        } else {
            return (
                <div style={{ height: 600, width: '100%' }}>
                    <DataGrid
                        getRowId={(row) => row.title}
                        rows={rows}
                        columns={columnsevents}
                        pageSize={8}
                        rowsPerPageOptions={[8]}
                        components={{
                            Toolbar: CustomToolbar,
                            NoRowsOverlay: () => (
                                <Stack height="100%" alignItems="center" justifyContent="center">
                                    No Events found
                                </Stack>
                            ),
                        }}
                    />
                    <NewEvent showForm={showNewEventForm} closeNewEventForm={closeNewEventForm} setIsLoading={setIsLoading} />
                </div>
            )
        }
    }


}



