import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { GeneralItems, PilotsItems, AtcItems } from '../listitems/listItems'
import { selectMember, selectToken, selectValid } from '../features/authSlice/authSlice'
import { useSelector, useDispatch } from 'react-redux'
import Time from '../TopBar/Time/Time'
import LiveInfo from '../TopBar/LiveInfo/LiveInfo'
import MainRoutes from '../Content/HomePage/Router/MainRoutes/MainRoutes'
import { NavLink } from 'react-router-dom'
import TopBarMenuiItems from '../TopBar/TopBarMenuiItems/TopBarMenuiItems'

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://vatil.org.il">
        Developed By Or Bahtari
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const mdTheme = createTheme();

function DashboardContent() {
  const [open, setOpen] = React.useState(true);
  const member = useSelector(selectMember);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openn = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open} style={{ backgroundColor: "white" }}>
          <Toolbar >
            <IconButton
              edge="start"
              color="black"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <IconButton>
              <Time />
            </IconButton>
            <IconButton>
              <LiveInfo />
            </IconButton>
            <div style={{ position: "relative", marginLeft: "auto" }}>
              <Button
                id="basic-button"
                aria-controls={openn ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openn ? 'true' : undefined}
                onClick={handleClick}
                style={{ color: "#142d54" }}
              >
                {member.first_name + " " + member.last_name}
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openn}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <TopBarMenuiItems />
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open} PaperProps={{
          sx: {
            backgroundColor: "#0a1a40"
          }
        }}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <a href="https://dashboard.vatil.org.il">
              <img alt="Qries" src="http://vatil.org.il/forum/uploads/monthly_2020_05/unnamed.png.067da7013243e3af97f8e7d2a36fc33d.png"></img>
            </a>
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon style={{ color: "white" }} />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            <GeneralItems />
            <Divider sx={{ my: 1 }} />
            <PilotsItems />
            <Divider sx={{ my: 1 }} />
            <AtcItems />
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '101vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container sx={{ mt: 2 }} maxWidth={"xl"}>
            <MainRoutes />
            <Copyright sx={{ pt: 3 }} />
          </Container>
        </Box>
      </Box >
    </ThemeProvider >
  );
}

export default function Dashboard() {
  return <DashboardContent />;
}








// {/* <Toolbar
// sx={{
//   pr: '24px', // keep right padding when drawer closed
// }}
// >
// <IconButton
//   edge="start"
//   color="black"
//   aria-label="open drawer"
//   onClick={toggleDrawer}
//   sx={{
//     marginRight: '36px',
//     ...(open && { display: 'none' }),
//   }}
// >
//   <MenuIcon />
// </IconButton>
// <Typography
//   component="h1"
//   variant="h5"
//   color="#142d54"
//   noWrap
//   sx={{ flexGrow: 10 }}
// >
// </Typography>
// <div>
//   <Button
//     id="basic-button"
//     aria-controls={openn ? 'basic-menu' : undefined}
//     aria-haspopup="true"
//     aria-expanded={openn ? 'true' : undefined}
//     onClick={handleClick}
//     style={{ color: "#142d54" }}
//   >
//     {member.first_name + " " + member.last_name}
//   </Button>
//   <Menu
//     id="basic-menu"
//     anchorEl={anchorEl}
//     open={openn}
//     onClose={handleClose}
//     MenuListProps={{
//       'aria-labelledby': 'basic-button',
//     }}
//   >
//     <MenuItem onClick={handleClose}>Profile</MenuItem>
//     <MenuItem onClick={handleClose}>Logout</MenuItem>
//   </Menu>
// </div>
// {/* <IconButton color="inherit">
//   <Badge badgeContent={4} color="primary">
//     <NotificationsIcon style={{ color: "#142d54" }} />
//   </Badge>
// </IconButton> */}
// </Toolbar > * /}