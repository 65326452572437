import React, { useState, useEffect } from 'react'
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Title from '../../../Title';
import { useSelector, useDispatch } from 'react-redux'
import { selectToken } from '../../../features/authSlice/authSlice'
import { setAtc, selectAtc } from '../../../features/atcSlice/atcSlice'
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';

const atccolumns = [
    { field: 'positionname', headerName: 'Callsign', width: 110 },
    { field: 'frequency', headerName: 'Frequency' },
];

const loadingrows = [
    { callsign: 'loading', frequency: 'loading' }
]

export default function LiveAtc() {
    const [isLoading, setIsLoading] = useState(true);
    const [duration, setDuration] = useState(2000);
    const token = useSelector(selectToken);
    const dispatch = useDispatch()
    const atc = useSelector(selectAtc);


    useEffect(() => {
        const interval = setInterval(async () => {
            var config = {
                method: 'get',
                url: process.env.REACT_APP_VATIL_API + '/general/getinfo',
                headers: {
                    'Authorization': `Bearer ${token} `
                }
            };
            await axios(config)
                .then(function (response) {
                    dispatch(setAtc({ atc: response.data.atc }));
                    setDuration(60000);
                    setIsLoading(false);
                })
                .catch(function (error) {
                    console.log(error);
                });
        }, duration)
        return () => clearInterval(interval);
    }, [isLoading])



    if (isLoading) {
        return (
            <React.Fragment>
                <Title >ATC Online</Title>
                <Typography component="p" variant="h4">
                    <div style={{ height: 400, width: '100%' }}>
                        <DataGrid
                            getRowId={(row) => row.callsign}
                            rows={loadingrows}
                            columns={atccolumns}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                            loading={isLoading}
                        />
                    </div>
                </Typography>
            </React.Fragment >
        );
    } else {
        if (atc.length > 0) {
            return (
                <React.Fragment>
                    <Title >ATC Online</Title>
                    <Typography component="p" variant="h4">
                        <div style={{ height: 371, width: '100%' }}>
                            <DataGrid
                                getRowId={(row) => row.positionname}
                                rows={atc}
                                columns={atccolumns}
                                pageSize={5}
                                rowsPerPageOptions={[5]}
                                loading={isLoading}
                            />
                        </div>
                    </Typography>
                </React.Fragment >
            );
        }
        else {
            return (
                <React.Fragment>
                    <Title >ATC Online</Title>
                    <Typography component="p" variant="h7">
                        No ATC Online
                    </Typography>
                </React.Fragment >
            )
        }

    }


}
