import { createSlice } from "@reduxjs/toolkit";


export const atcSlice = createSlice({
    name: 'atc',
    initialState: {
        atclist: null,
    },
    reducers: {
        setAtc: (state, action) => {
            const { atc } = action.payload;
            state.atclist = atc;
        }
    }
})

export const { setAtc } = atcSlice.actions;
export const selectAtc = (state) => state.atc.atclist;
export default atcSlice.reducer;