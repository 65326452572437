import SendIcon from '@mui/icons-material/Send';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Title from '../../Title';
import { selectMember, selectToken } from '../../features/authSlice/authSlice';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';



export default function AtcFeedback() {
    const member = useSelector(selectMember)
    const token = useSelector(selectToken);
    const [controller, setController] = useState();
    const [date, setDate] = useState(new Date);
    const [position, setPosition] = useState();
    const [feedback, setFeedback] = useState();
    const [button, setButton] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };


    const handlesubmit = (e) => {
        e.preventDefault();
        setButtonLoading(true);
        var data = JSON.stringify({
            "full_name": member.first_name + " " + member.last_name,
            "vid": member.vid,
            "email_address": member.email,
            "controller": controller,
            "date": moment(date).format('DD/MM/YYYY'),
            "position": position,
            "feedback": feedback
        });

        var config = {
            method: 'post',
            url: process.env.REACT_APP_VATIL_API + '/general/atcfeedback',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token} `
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                handleOpen();
                setButtonLoading(false);
                setButton(true);
                setController("");
                setDate("");
                setPosition("");
                setFeedback("");
            })
            .catch(function (error) {
                console.log(error);
            });

    }


    useEffect(() => {
        if (controller && date && feedback && position) {
            setButton(false);
        } else {
            setButton(true);
        }
    }, [controller, date, feedback, position])


    return (
        <div style={{ marginLeft: 200 }}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={8} lg={9}>
                    <Paper sx={{ height: "750px" }}>
                        <Title >ATC Feedback</Title>
                        <form style={{ width: "70%", marginLeft: "10px" }}>
                            <TextField
                                disabled
                                id="outlined-disabled"
                                label="Name"
                                value={member.first_name + " " + member.last_name}
                                margin="normal"
                                fullWidth
                            />
                            <TextField
                                disabled
                                id="outlined-disabled"
                                label="VATSIM ID"
                                value={member.vid}
                                margin="normal"
                                fullWidth
                            />
                            <TextField
                                disabled
                                id="outlined-disabled"
                                label="Email Address"
                                value={member.email}
                                margin="normal"
                                fullWidth
                            />
                            <TextField
                                id="outlined-disabled"
                                label="Controller Name/Vatsim ID"
                                margin="normal"
                                fullWidth
                                onChange={(event) => { setController(event.target.value) }}
                            />
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    renderInput={(params) => <TextField {...params} fullWidth required sx={{ marginTop: "10px", marginBottom: "10px" }} />}
                                    label="Date"
                                    onChange={(newValue) => {
                                        setDate(newValue.$d);
                                    }}
                                    value={date}
                                    ampm={false}
                                    maxDate={new Date()}
                                    inputFormat="DD/MM/YYYY"
                                />
                            </LocalizationProvider>
                            <FormControl fullWidth sx={{ marginTop: "5px" }}>
                                <InputLabel >ATC Position</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={position}
                                    label="Department"
                                    onChange={(event) => setPosition(event.target.value)}
                                    margin="normal"
                                >
                                    <MenuItem value={"LLBG Ground (LLBG_W_GND/LLBG_E_GND)"}>LLBG Ground (LLBG_W_GND/LLBG_E_GND)</MenuItem>
                                    <MenuItem value={"LLBG Tower (LLBG_D_TWR)"}>LLBG Tower (LLBG_D_TWR)</MenuItem>
                                    <MenuItem value={"LLBG Dep/APP (LLBG_D_APP)"}>LLBG Dep/App (LLBG_D_APP)</MenuItem>
                                    <MenuItem value={"Tel-Aviv Control (LLLL_CTR)"}>Tel-Aviv Control (LLLL_CTR)</MenuItem>
                                    <MenuItem value={"LLHZ Delivery (LLHZ_DEL)"}>LLHZ Delivery (LLHZ_DEL)</MenuItem>
                                    <MenuItem value={"LLHZ Tower (LLHZ_TWR)"}>LLHZ Tower (LLHZ_TWR)</MenuItem>
                                    <MenuItem value={"LLHA Tower (LLHA_TWR)"}>LLHA Tower (LLHA_TWR)</MenuItem>
                                    <MenuItem value={"LLIB Tower (LLIB_TWR)"}>LLIB Tower (LLIB_TWR)</MenuItem>
                                    <MenuItem value={"LLER Tower (LLER_TWR)"}>LLER Tower (LLER_TWR)</MenuItem>
                                    <MenuItem value={"Pluto (LLPT_CTR)"}>Pluto (LLPT_CTR)</MenuItem>
                                    <MenuItem value={"South Control (LLSC_CTR)"}>South Control (LLSC_CTR)</MenuItem>
                                </Select>
                            </FormControl>

                            <TextField
                                id="outlined-disabled"
                                label="Your Feedback"
                                margin="normal"
                                fullWidth
                                required
                                multiline
                                rows={5}
                                onChange={(event) => setFeedback(event.target.value)}
                                value={feedback}
                            />
                            <LoadingButton
                                onClick={handlesubmit}
                                endIcon={<SendIcon />}
                                loading={buttonLoading}
                                loadingPosition="end"
                                variant="contained"
                                disabled={button}
                            >
                                Submit
                            </LoadingButton>
                        </form>

                    </Paper>
                </Grid>
            </Grid >
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Feedback Sent!
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Thank you for your feedback! {<br />}
                        You meesage was delivered to the relevant authorities.
                    </Typography>
                </Box>
            </Modal>
        </div>
    )

}