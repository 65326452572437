import React, { useState, useEffect } from 'react'
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import Title from '../../../Title'
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import { useSelector, useDispatch } from 'react-redux'
import { selectMember, selectToken } from '../../../features/authSlice/authSlice'
import Typography from '@mui/material/Typography'
import axios from 'axios'

export default function CancelBooking(props) {
    const { showCancel, closeBookingForm, setIsLoading, shift, name } = props;
    const member = useSelector(selectMember);
    const token = useSelector(selectToken);
    const [loadingButton, setLoadingButton] = useState(false);
    const [submitButton, setSubmitButton] = useState(false);




    const handleSubmit = (e) => {
        setLoadingButton(true);
        setSubmitButton(true);
        var data = JSON.stringify({
            "shiftId": shift._id,
        });

        var config = {
            method: 'post',
            url: process.env.REACT_APP_VATIL_API + '/events/removebooking',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                setTimeout(() => {
                    closeBookingForm(); setLoadingButton(false); setSubmitButton(false); setIsLoading(true);;
                }, 3000)
            })
            .catch(function (error) {
                console.log(error);
            });


    }

    const handleClose = () => {
        setLoadingButton(true);
        setSubmitButton(false);
        closeBookingForm();
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };


    useEffect(() => {

    }, []);


    if (shift) {
        return (
            <Modal
                open={showCancel}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ marginBottom: 20 }}
            >
                <Stack component="form" noValidate spacing={3} sx={style}>
                    <Title >Cancel ATC Booking for event</Title>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Are you sure you want to cancel booking for <br></br>{name}  from {shift.start} to {shift.end}?
                    </Typography>
                    <Typography sx={{ marginTop: 1 }}>
                        <LoadingButton
                            onClick={handleSubmit}
                            loading={loadingButton}
                            loadingPosition="end"
                            variant="contained"
                            sx={{ backgroundColor: "#0a1a40" }}
                            size="large"
                        >
                            Yes
                        </LoadingButton>
                        <Button onClick={closeBookingForm} color="error" sx={{ marginLeft: "10px" }} size="large" variant="contained">No</Button>
                    </Typography>
                </Stack>
            </Modal>
        )
    }

}