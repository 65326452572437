import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux'
import { selectMember, selectToken } from '../../features/authSlice/authSlice'
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Title from '../../Title'
import moment from 'moment'
import NewRequestForm from './NewRequestForm'
import RemoveRequest from './RemoveRequest'
import ModifyRequest from './ModifyRequest'


export default function AtcRequests() {
    const member = useSelector(selectMember);
    const token = useSelector(selectToken);
    const [requests, setRequests] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [showForm, setShowForm] = useState(false);
    const [showCancel, setShowCancel] = useState(false);
    const [showModify, setShowModify] = useState(false);
    const [eventId, setEventId] = useState();
    const [title, setTitle] = useState();



    const showNewRequestForm = () => {
        setShowForm(true);
    }

    const closeRequestForm = () => {
        setShowForm(false);
    }

    const closeCancelForm = () => {
        setShowCancel(false);
    }

    const closeModifyForm = () => {
        setShowModify(false);
    }

    const columnsrequests = [
        { field: 'title', headerName: 'Title', width: 200 },
        {
            field: 'start',
            headerName: 'Start',
            width: 250,
            renderCell: (cellValues) => {
                let date = moment(cellValues.row.start).format('DD/MM/yyyy HH:mm');
                return (
                    date
                )
            }
        },
        {
            field: 'end',
            headerName: 'End',
            width: 250,
            renderCell: (cellValues) => {
                let date = moment(cellValues.row.end).format('DD/MM/yyyy HH:mm');
                return (
                    date
                )
            }
        },
        {
            field: 'managerapproval',
            headerName: 'Status',
            width: 150,
            renderCell: (cellValues) => {
                if (cellValues.row.managerapproval === false) {
                    return (
                        "Pending"
                    )
                } else {
                    return "Approved"
                }
            }
        },
        {
            field: "actions",
            width: 220,
            headerName: 'Actions',
            renderCell: (cellValues) => {
                if (cellValues.row.managerapproval === false) {
                    return (
                        <div>
                            <Button
                                sx={{ backgroundColor: "#0a1a40" }}
                                variant="contained"
                                onClick={async (e) => {
                                    e.preventDefault();
                                    setEventId(cellValues.row._id);
                                    setShowModify(true);
                                    // handleClick(event, cellValues);
                                }}
                            >
                                Modify
                            </Button>
                            <Button
                                sx={{ marginLeft: 1 }}
                                variant="contained"
                                color="error"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setEventId(cellValues.row._id);
                                    setTitle(cellValues.row.title);
                                    setShowCancel(true);
                                }}
                            >
                                Cancel
                            </Button>
                        </div>
                    );
                } else {
                    return (
                        <Button
                            sx={{ marginLeft: 1 }}
                            variant="contained"
                            color="error"
                            onClick={(e) => {
                                e.preventDefault();
                                setEventId(cellValues.row._id);
                                setTitle(cellValues.row.title);
                                setShowCancel(true);
                            }}
                        >
                            Cancel
                        </Button>
                    )
                }

            }
        }
    ];


    const loadingrows = [
        { title: 'loading', pilots: 'loading', route: 'loading', start: 'loading', status: 'loading' }
    ]





    useEffect(() => {
        var data = JSON.stringify({
            "vid": member.vid
        });

        var config = {
            method: 'post',
            url: process.env.REACT_APP_VATIL_API + '/calendar/getrequests',
            headers: {
                'Authorization': `Bearer ${token} `,
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                setRequests(response.data);
                setIsLoading(false);
            })
            .catch(function (error) {
                console.log(error);
            });

    }, [isLoading]);



    if (isLoading) {
        return (
            <div>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={8} lg={13}>
                        <Paper sx={{ width: 1500 }}>
                            <Title >My ATC Requests</Title>
                            <Button
                                color="success"
                                variant="contained"
                                onClick={showNewRequestForm}
                            >
                                New Request
                            </Button>
                            <div style={{ height: 600, width: '100%', marginTop: 10, marginLeft: 30 }}>
                                <DataGrid
                                    getRowId={(row) => row.title}
                                    rows={loadingrows}
                                    columns={columnsrequests}
                                    pageSize={5}
                                    rowsPerPageOptions={[5]}
                                    loading={isLoading}
                                />
                            </div>
                        </Paper>
                    </Grid>
                </Grid >
            </div>
        )
    } else {
        if (requests.length > 0) {
            return (
                <div>
                    <Grid container spacing={3} >
                        <Grid item xs={12} md={8} lg={13}>
                            <Paper sx={{ width: 1200 }}>
                                <Title >My ATC Requests</Title>
                                <Button
                                    color="success"
                                    variant="contained"
                                    onClick={showNewRequestForm}
                                >
                                    New Request
                                </Button>
                                <div style={{ height: 700, width: '95%', marginTop: 10, marginLeft: 29 }}>
                                    <DataGrid
                                        getRowId={(row) => row.title}
                                        rows={requests}
                                        columns={columnsrequests}
                                        pageSize={15}
                                        rowsPerPageOptions={[15]}
                                        loading={isLoading}
                                    />
                                </div>
                            </Paper>
                        </Grid>
                    </Grid >
                    <NewRequestForm showForm={showForm} closeRequestForm={closeRequestForm} setIsLoading={setIsLoading} />
                    <RemoveRequest showCancel={showCancel} closeCancelForm={closeCancelForm} setIsLoading={setIsLoading} eventId={eventId} title={title} />
                    <ModifyRequest showModify={showModify} closeModifyForm={closeModifyForm} setIsLoading={setIsLoading} event={eventId} />
                </div>
            )
        } else {
            return (
                <div>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={8} lg={13}>
                            <Paper >
                                <Title >My ATC Requests</Title>
                                <Button
                                    color="success"
                                    variant="contained"
                                    onClick={showNewRequestForm}
                                >
                                    New Request
                                </Button>
                                <h2>No ATC requests exist</h2>
                            </Paper>
                        </Grid>
                    </Grid >
                    <NewRequestForm showForm={showForm} closeRequestForm={closeRequestForm} setIsLoading={setIsLoading} />
                </div >
            )
        }

    }

}

