import { createSlice } from "@reduxjs/toolkit";


export const flightSlice = createSlice({
    name: 'flight',
    initialState: {
        arrivals: null,
        departures: null
    },
    reducers: {
        setFlights: (state, action) => {
            const { arrivals, departures } = action.payload;
            state.arrivals = arrivals;
            state.departures = departures;
        }
    }
})

export const { setFlights } = flightSlice.actions;
export const selectArrivals = (state) => state.flight.arrivals;
export const selectDepartures = (state) => state.flight.departures;
export default flightSlice.reducer;