import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { Buffer } from 'buffer';
import { useSelector, useDispatch } from 'react-redux'
import { selectMember, selectToken, selectValid } from '../../../features/authSlice/authSlice'


export default function EventImage(props) {
    const { url, height, width } = props;
    const token = useSelector(selectToken);
    const [logo, setLogo] = useState();



    useEffect(() => {
        axios
            .get(
                `${process.env.REACT_APP_VATIL_API}/${url}`,
                {
                    responseType: "arraybuffer",
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            )
            .then((response) => {
                setLogo(Buffer.from(response.data, "binary").toString("base64"))
            }
            );
    }, []);


    if (width && height) {
        return (
            <img src={`data:image/png;charset=utf-8;base64,${logo}`} alt="logo" width={width} height={height} />
        )
    } else {
        return (
            <img src={`data:image/png;charset=utf-8;base64,${logo}`} alt="logo" />
        )
    }
}




// http://localhost:5000/uploads/eventslogos/1673616430241-Kyiv_-_Tel_Aviv_City_Shuttle.thumb.png.8be45ca4165287b905b02733523fd2b5.png