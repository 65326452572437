import React, { useState, useEffect } from 'react'
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import Title from '../../../Title'
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSelector, useDispatch } from 'react-redux'
import { selectMember, selectToken } from '../../../features/authSlice/authSlice'
import axios from 'axios'

export default function Book(props) {
    const { showBook, closeBookingForm, setIsLoading, shift, name } = props;
    const member = useSelector(selectMember);
    const token = useSelector(selectToken);
    const [loadingButton, setLoadingButton] = useState(false);
    const [submitButton, setSubmitButton] = useState(false);




    const handleSubmit = (e) => {
        setLoadingButton(true);
        setSubmitButton(true);
        var data = JSON.stringify({
            "member": member,
            "shiftId": shift._id,
            "start": shift.startDate,
            "end": shift.endDate,
            "callsign": name,
        });

        var config = {
            method: 'post',
            url: process.env.REACT_APP_VATIL_API + '/events/atcbooking',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                setTimeout(() => {
                    closeBookingForm(); setLoadingButton(false); setSubmitButton(false); setIsLoading(true);;
                }, 3000)
            })
            .catch(function (error) {
                console.log(error);
            });


    }

    const handleClose = () => {
        setLoadingButton(false);
        setSubmitButton(false);
        closeBookingForm();
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };


    useEffect(() => {

    }, []);


    if (shift) {
        return (
            <Modal
                open={showBook}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ marginBottom: 20 }}
            >
                <Stack component="form" noValidate spacing={3} sx={style}>
                    <Title >ATC Booking For Event</Title>
                    <TextField id="outlined-basic" label="Position" variant="outlined" value={name} disabled />
                    <TextField id="outlined-basic" label="Time" variant="outlined" value={shift.start + "-" + shift.end} disabled />


                    <LoadingButton
                        onClick={handleSubmit}
                        endIcon={<SendIcon />}
                        loading={loadingButton}
                        loadingPosition="end"
                        variant="contained"
                        disabled={submitButton}
                    >
                        Submit
                    </LoadingButton>
                </Stack>
            </Modal>
        )
    }

}