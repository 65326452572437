import React, { useEffect, useState, Fragment } from 'react'
import ArrivalIcon from '@mui/icons-material/FlightLand';
import DepartureIcon from '@mui/icons-material/FlightTakeoff'
import AtcIcon from '@mui/icons-material/HeadsetMic';
import { selectAtc, selectDepartures, selectArrivals, setInfo } from '../../features/infoSlice/infoSlice';
import { selectToken } from '../../features/authSlice/authSlice'
import { useSelector, useDispatch } from 'react-redux'
import LoadingButton from '@mui/lab/LoadingButton';
import axios from 'axios';



export default function LiveInfo(props) {
    const atc = useSelector(selectAtc);
    const token = useSelector(selectToken);
    const arrivals = useSelector(selectArrivals);
    const departures = useSelector(selectDepartures);
    const [isLoading, setIsLoading] = useState(true);
    const [duration, setDuration] = useState(2000);
    const dispatch = useDispatch()

    useEffect(() => {
        const interval = setInterval(async () => {
            var config = {
                method: 'get',
                url: process.env.REACT_APP_VATIL_API + '/general/getinfo',
                headers: {
                    'Authorization': `Bearer ${token} `
                }
            };
            await axios(config)
                .then(function (response) {
                    dispatch(setInfo({ atcnum: response.data.atcnum, arrivals: response.data.arrivals, departures: response.data.departures }));
                    setDuration(60000);
                    setIsLoading(false);
                })
                .catch(function (error) {
                    console.log(error);
                });
        }, duration)
        return () => clearInterval(interval);
    }, [isLoading])




    if (isLoading) {
        return (
            <h5><ArrivalIcon />&nbsp;<LoadingButton loading />&nbsp;<DepartureIcon />&nbsp;<LoadingButton loading />&nbsp;<AtcIcon />&nbsp;<LoadingButton loading /></h5>
        )
    } else {
        return (
            <div style={{ fontSize: "18px", fontWeight: "bold", fontFamily: "Roboto", color: "#142d54" }}><ArrivalIcon />&nbsp;{arrivals}&nbsp;<DepartureIcon />&nbsp;{departures}&nbsp;<AtcIcon />&nbsp;{atc}</div>
        )
    }
}