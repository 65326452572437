import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import { GridToolbarContainer, } from '@mui/x-data-grid';
import AssignRole from './AssignRole';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux'
import { selectMember, selectToken } from '../../../../features/authSlice/authSlice'



export default function Roles() {
    const token = useSelector(selectToken);
    const [isLoading, setIsLoading] = useState(true);
    const [rows, setRows] = useState();
    const [showForm, setShowForm] = useState(false);


    const columsadmin = [
        { field: 'vid', headerName: 'VATSIM ID', width: 200 },
        {
            field: 'first_name',
            headerName: 'Name',
            width: 200,
            renderCell: (cellValues) => {
                return (
                    `${cellValues.row.first_name} ${cellValues.row.last_name}`
                )
            }
        },
        { field: 'role', headerName: 'Role', width: 200 },
        { field: 'subroles', headerName: 'Subroles', width: 200 },
    ];
    const loadingrows = [{ vid: "loading", first_name: "loading", role: "loading", subroles: "loading" }]

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <Button
                    variant="text"
                    onClick={async (e) => {
                        e.preventDefault();
                        setShowForm(true);
                    }}
                >
                    Assign/Modify Role
                </Button>
            </GridToolbarContainer>
        );
    }


    useEffect(() => {
        var config = {
            method: 'get',
            url: process.env.REACT_APP_VATIL_API + '/general/getalladmins',
            headers: {
                'Authorization': `Bearer ${token} `,
            }
        };

        axios(config)
            .then(function (response) {
                setRows(response.data);
                setIsLoading(false);
            })
            .catch(function (error) {
                console.log(error);
            });

    }, [isLoading])


    if (isLoading) {
        return (
            <div style={{ height: 600, width: '100%' }}>
                <DataGrid
                    getRowId={() => Math.random()}
                    rows={loadingrows}
                    columns={columsadmin}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    loading={isLoading}
                />
            </div>
        )
    } else {
        if (rows) {
            return (
                <div style={{ height: 600, width: '100%' }}>
                    <DataGrid
                        getRowId={() => Math.random()}
                        rows={rows}
                        columns={columsadmin}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        loading={isLoading}
                        components={{
                            Toolbar: CustomToolbar,
                        }}
                    />
                    <AssignRole showForm={showForm} setShowForm={setShowForm} setIsLoading={setIsLoading} />
                </div>
            )
        } else {
            return (
                <div>
                    <Button
                        sx={{ backgroundColor: "#0a1a40" }}
                        variant="contained"
                        onClick={async (e) => {
                            e.preventDefault();
                            // handleClick(event, cellValues);
                        }}
                    >
                        Assign Role
                    </Button>
                    <h1>No members with roles exist.</h1>
                    <AssignRole showForm={showForm} setShowForm={setShowForm} setIsLoading={setIsLoading} />
                </div>

            )

        }

    }

}