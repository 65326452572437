import React from 'react'
import Iframe from 'react-iframe'


export default function Sop() {




    return (
        <Iframe url="https://vatil.org.il/site/documents/operations/sop-vatil.pdf"
            width="100%"
            height="1500px"
            id="myId"
            className="myClassname"
            display="initial"
            position="relative" />
    )
}