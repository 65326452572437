import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux'
import { selectToken } from '../../features/authSlice/authSlice'
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Title from '../../Title'


export default function AtcRoster() {
    const token = useSelector(selectToken);
    const [controllers, setControllers] = useState();
    const [isLoading, setIsLoading] = useState(true);

    const columnscontrollers = [
        { field: 'vid', headerName: 'VATSIM ID', width: 150 },
        { field: 'name', headerName: 'Name', width: 270 },
        { field: 'rating', headerName: 'Rating', width: 220 },
        { field: 'qualified', headerName: 'Active?', width: 150 },
        { field: 'positions', headerName: 'Positions', width: 350 },
        // { field: 'last3monthshours', headerName: 'Hours in last 3 months', width: 200 },
    ];


    const loadingrows = [
        { vid: 'loading', name: 'loading', rating: 'loading', qualified: 'loading', }
    ]

    useEffect(() => {
        var config = {
            method: 'get',
            url: process.env.REACT_APP_VATIL_API + '/general/getcontrollers',
            headers: {
                'Authorization': `Bearer ${token} `
            }
        };

        axios(config)
            .then(function (response) {
                const sortedData = response.data.sort((a, b) => b.last3monthshours - a.last3monthshours);
                setControllers(sortedData);
                setIsLoading(false);
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [isLoading])


    if (isLoading) {
        return (
            <Grid container spacing={3}>
                <Grid item xs={12} md={8} lg={13}>
                    <Paper >
                        <Title >ATC Roster</Title>
                        <div style={{ height: 600, width: '100%' }}>
                            <DataGrid
                                getRowId={(row) => row.vid}
                                rows={loadingrows}
                                columns={columnscontrollers}
                                pageSize={5}
                                rowsPerPageOptions={[5]}
                                loading={isLoading}
                            />
                        </div>
                    </Paper>
                </Grid>
            </Grid >
        )
    } else {
        return (
            <Grid container spacing={3}>
                <Grid item xs={12} md={8} lg={13}>
                    <Paper >
                        <Title >ATC Roster</Title>
                        <div style={{ height: 700, width: '100%' }}>
                            <DataGrid
                                getRowId={(row) => row.vid}
                                rows={controllers}
                                columns={columnscontrollers}
                                pageSize={15}
                                rowsPerPageOptions={[15]}
                                loading={isLoading}
                                components={{ Toolbar: GridToolbar }}
                            />
                        </div>
                    </Paper>
                </Grid>
            </Grid >



        )
    }

}