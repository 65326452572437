import SendIcon from '@mui/icons-material/Send';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import axios from 'axios';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import React, { useEffect, useState } from 'react';
import { FileUploader } from "react-drag-drop-files";
import { useSelector } from 'react-redux';
import Title from '../../../../Title';
import { selectToken } from '../../../../features/authSlice/authSlice';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export default function NewEvent(props) {
    const { showForm, setIsLoading, closeNewEventForm } = props;
    const token = useSelector(selectToken);
    const [show, setShow] = useState();
    const [description, setDescription] = useState();
    const [title, setTitle] = useState();
    const [route, setRoute] = useState();
    const [start, setStart] = useState(null);
    const [end, setEnd] = useState(null);
    const [minStart, setMinStart] = useState();
    const [minEnd, setMinEnd] = useState();
    const [noAtc, setNoAtc] = useState(true);
    const [atcStart, setAtcStart] = useState(null);
    const [atcEnd, setAtcEnd] = useState(null);
    const [maxAtcStart, setMaxAtcStart] = useState()
    const [minAtcStart, setMinAtcStart] = useState();
    const [maxAtcEnd, setMaxAtcEnd] = useState();
    const [minAtcEnd, setMinAtcEnd] = useState();
    const [loadingButton, setLoadingButton] = useState(false);
    const [submitButton, setSubmitButton] = useState(true);
    const [logo, setLogo] = useState(null);
    const [positions, setPositions] = useState([]);
    const [duration, setDuration] = useState();
    const fileTypes = ["PNG"];
    const theme = useTheme();
    const [positionsList, setPositionsList] = useState([]);
    const [valid, setValid] = useState({ title: false, positions: false, start: false, end: false, atcStart: false, atcEnd: false, description: false, route: false, logo: false, duration: false });



    const style = {
        position: 'absolute',
        top: '54%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const handleSubmit = async (e) => {
        setSubmitButton(true);
        setLoadingButton(true);
        const formData = new FormData();
        formData.append('title', title);
        formData.append('start', start);
        formData.append('end', end);
        formData.append('atcStart', atcStart);
        formData.append('atcEnd', atcEnd);
        formData.append('description', description);
        formData.append('positions', JSON.stringify(positions));
        formData.append('route', route);
        formData.append('logo', logo);
        formData.append('duration', duration);
        try {
            axios.post(process.env.REACT_APP_VATIL_API + '/events/newevent', formData, {
            }).then(res => {
                setTimeout(() => { handleClose(); setIsLoading(true) }, 2500);
            });
        } catch (error) {
            console.error('Failed to submit files.');
        }
    }
    const handleClose = () => {
        closeNewEventForm();
        setTitle(null);
        setRoute(null);
        setStart(null);
        setEnd(null);
        setMinStart(null);
        setMinEnd(null);
        setAtcStart(null);
        setAtcEnd(null);
        setMaxAtcStart(null);
        setMinAtcStart(null);
        setMaxAtcEnd(null);
        setMinAtcEnd(null);
        setLoadingButton(null);
        setSubmitButton(null);
        setLogo(null);
        setPositions([]);
        setPositionsList([]);
        setDuration(null);
        setNoAtc(true);
    }

    const handleChange = (event) => {
        setPositions(event.target.value);
        if (event.target.value < 1) {
            setValid({ ...valid, positions: false })
        } else {
            setValid({ ...valid, positions: true })
        }
    };

    const handleNoAtc = (event) => {
        if (event.target.checked === true) {
            setNoAtc(event.target.checked);
            setAtcStart(null);
            setAtcEnd(null);
            setDuration('');
            setPositions([]);
        } else {
            setNoAtc(event.target.checked);
        }
    }

    useEffect(() => {
        dayjs.extend(isSameOrAfter)
        dayjs.extend(isSameOrBefore)
        setMinStart(dayjs().subtract(2, 'hour'))
        setShow(showForm);
        if (positionsList.length < 1) {
            var config = {
                method: 'get',
                url: process.env.REACT_APP_VATIL_API + '/calendar/getpositions',
                headers: {
                    'Authorization': `Bearer ${token} `,
                }
            };

            axios(config)
                .then(function (response) {
                    setPositionsList(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        }

        validate();

    }, [showForm, valid])


    const validate = () => {
        if ((noAtc && title && description && route && logo) || (!noAtc && title && start && end && atcStart && atcEnd && description && route && logo && duration)) {
            setSubmitButton(false);
        } else {
            setSubmitButton(true);
        }
    }



    return (
        <Modal
            open={show}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ marginBottom: 5 }}
        >
            <Grid container spacing={2} sx={style}>
                <Title >New Event Form</Title>
                <Grid item lg={10}>
                    <TextField
                        id="outlined-disabled"
                        label="Title"
                        value={title}
                        fullWidth
                        onChange={(e) => {
                            if (e.target.value) {
                                setTitle(e.target.value);
                                setValid({ ...valid, title: true })
                            } else {
                                setTitle(e.target.value);
                                setValid({ ...valid, title: false })
                            }
                        }}
                    />
                </Grid>
                <Grid item lg={2}>
                    <FormControlLabel control={<Checkbox checked={noAtc} onChange={handleNoAtc} />} label="No ATC" />
                </Grid>
                <Grid item lg={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            renderInput={(params) => <TextField {...params} fullWidth required />}
                            label="Start Time (UTC)"
                            onChange={(newValue) => {
                                if (newValue === null) {
                                    setStart(null);
                                    setEnd(null);
                                    setValid({ ...valid, start: false, end: false });
                                } else {
                                    let minEndDateTime = newValue.clone().add(90, 'minute');
                                    let minAtcStart = newValue.clone();
                                    setMinAtcStart(minAtcStart);
                                    setMinAtcEnd(minAtcStart)
                                    setMinEnd(minEndDateTime);
                                    setStart(newValue);
                                    setEnd(null);
                                    setAtcStart(null);
                                    setAtcEnd(null);
                                    setValid({ ...valid, start: true, end: false, atcStart: false, atcEnd: false });
                                }

                            }}
                            value={start}
                            ampm={false}
                            minDateTime={minStart}
                            disablePast
                            inputFormat="DD/MM/YYYY HH:mm"
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item lg={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            renderInput={(params) => <TextField {...params} fullWidth required />}
                            label="End Time (UTC)"
                            onChange={(newValue) => {
                                if (newValue === null) {
                                    setEnd(null);
                                    setValid({ ...valid, end: false });
                                } else {
                                    if (newValue.isSameOrAfter(minEnd)) {
                                        let maxAtcEndTime = newValue.clone();
                                        setEnd(newValue);
                                        setMaxAtcEnd(maxAtcEndTime)
                                        setMaxAtcStart(maxAtcEndTime)
                                        setAtcStart(null);
                                        setAtcEnd(null);
                                        setValid({ ...valid, end: true, atcStart: false, atcEnd: false });
                                    } else {
                                        setEnd(newValue);
                                        setValid({ ...valid, end: false, atcStart: false, atcEnd: false });
                                    }
                                }
                            }}
                            value={end}
                            minDateTime={minEnd}
                            ampm={false}
                            inputFormat="DD/MM/YYYY HH:mm"
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item lg={12}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-multiple-chip-label">Positions</InputLabel>
                        <Select
                            labelId="demo-multiple-chip-label"
                            label="Positions"
                            id="demo-multiple-chip"
                            multiple
                            disabled={noAtc}
                            value={positions}
                            onChange={handleChange}
                            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((value) => (
                                        <Chip key={value.name} label={value.name} />
                                    ))}
                                </Box>
                            )}
                            MenuProps={MenuProps}
                        >
                            {positionsList.map((position) => (
                                <MenuItem
                                    key={position._id}
                                    value={position}
                                    style={getStyles(position.name, positions, theme)}
                                >
                                    {position.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item lg={4}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            renderInput={(params) => <TextField {...params} fullWidth required />}
                            label="ATC Start Time (UTC)"
                            onChange={(newValue) => {
                                if (newValue === null) {
                                    setAtcStart(null);
                                    setAtcEnd(null);
                                    setValid({ ...valid, atcStart: false, atcEnd: false });
                                } else {
                                    setAtcStart(newValue);
                                } setValid({ ...valid, atcStart: true });

                            }}
                            value={atcStart}
                            ampm={false}
                            minDateTime={minAtcStart}
                            maxDateTime={maxAtcStart}
                            disablePast
                            inputFormat="DD/MM/YYYY HH:mm"
                            disabled={noAtc}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item lg={4}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            renderInput={(params) => <TextField {...params} fullWidth required />}
                            label="ATC End Time (UTC)"
                            onChange={(newValue) => {
                                if (newValue === null) {
                                    setEnd(null);
                                    setValid({
                                        ...valid,
                                        atcEnd: false,
                                    })
                                } else {
                                    if (newValue.isSameOrBefore(maxAtcEnd)) {
                                        setAtcEnd(newValue);
                                        setValid({
                                            ...valid,
                                            atcEnd: true,
                                        })
                                    } else {
                                        setAtcEnd(newValue);
                                        setValid({
                                            ...valid,
                                            atcEnd: false,
                                        })
                                    }
                                }
                            }}
                            value={atcEnd}
                            maxDateTime={maxAtcEnd}
                            minDateTime={minAtcEnd}
                            ampm={false}
                            inputFormat="DD/MM/YYYY HH:mm"
                            disabled={noAtc}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item lg={4}>
                    <TextField
                        id="outlined-disabled"
                        label="Shift Duration (minutes)"
                        type="number"
                        value={duration}
                        disabled={noAtc}
                        fullWidth
                        onChange={(e) => {
                            if (e.target.value) {
                                setDuration(e.target.value);
                                setValid({ ...valid, duration: true })
                            } else {
                                setTitle(e.target.value);
                                setValid({ ...valid, duration: false })
                            }
                        }}
                    />
                </Grid>
                <Grid item lg={12}>
                    <TextField
                        id="outlined-multiline-static"
                        label="Description"
                        multiline
                        fullWidth
                        rows={6}
                        onChange={(e) => {
                            if (e.target.value) {
                                setDescription(e.target.value);
                                setValid({ ...valid, description: true })
                            } else {
                                setDescription(e.target.value);
                                setValid({ ...valid, description: false })
                            }
                        }} />
                </Grid>
                <Grid item lg={12}>
                    <TextField
                        id="outlined-multiline-static"
                        label="Route"
                        multiline
                        fullWidth
                        rows={4}
                        onChange={(e) => {
                            if (e.target.value) {
                                setRoute(e.target.value);
                                setValid({ ...valid, route: true })
                            } else {
                                setRoute(e.target.value);
                                setValid({ ...valid, route: false })
                            }
                        }} />
                </Grid>
                <Grid item lg={12}>
                    <FileUploader handleChange={(file) => { setLogo(file); setValid({ ...valid, logo: true }) }} name='logo' types={fileTypes} label=" Upload or drop a logo right here" />
                </Grid>
                <Grid item lg={12}>
                    <LoadingButton
                        onClick={handleSubmit}
                        endIcon={<SendIcon />}
                        loading={loadingButton}
                        loadingPosition="end"
                        fullWidth
                        variant="contained"
                        disabled={submitButton}
                    >
                        Submit
                    </LoadingButton>
                </Grid>
            </Grid>
        </Modal >
    )
}