import ContactIcon from '@mui/icons-material/ContactPage';
import EventIcon from '@mui/icons-material/Event';
import DownloadIcon from '@mui/icons-material/FileDownload';
import GroupIcon from '@mui/icons-material/Groups';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import AirportIcon from '@mui/icons-material/LocalAirport';
import PersonIcon from '@mui/icons-material/Person';
import PolicyIcon from '@mui/icons-material/Policy';
import SpeedIcon from '@mui/icons-material/Speed';
import SchoolIcon from '@mui/icons-material/School';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import FeedbackIcon from '@mui/icons-material/Feedback';
import MicIcon from '@mui/icons-material/Mic';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { selectMember } from '../features/authSlice/authSlice';

export function GeneralItems() {
  const itemsList = [
    { name: "Dashboard", icon: <SpeedIcon />, route: "/home" },
    { name: "Profile", icon: <PersonIcon />, route: "/profile" },
    { name: "Policy", icon: <PolicyIcon />, route: "/policy" },
    { name: "Become An ATC", icon: <HowToRegIcon />, route: "/newatcform" },
    { name: "Contact Form", icon: <ContactIcon />, route: "/contact" },
    { name: "ATC Feedback", icon: <FeedbackIcon />, route: "/atcfeedback" },
    { name: "ATC Roster", icon: <GroupIcon />, route: "/atcroster" },
    { name: "Upcoming Event", icon: <EventIcon />, route: "/event" },
    { name: "My ATC Requests", icon: <EventIcon />, route: "/atcrequests" },
  ];
  return (
    <React.Fragment>
      {itemsList.map((item) => {
        return (
          <Link to={item.route} style={{ textDecoration: 'none' }} key={item.route}>
            <ListItemButton sx={{
              color: "white",
              ":hover": {
                backgroundColor: "#142d54",
              }
            }}>
              <ListItemIcon sx={{ color: "white" }}>
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItemButton>
          </Link>
        )
      })}
    </React.Fragment>
  )
}


export function PilotsItems() {
  const itemsList = [
    { name: "Ben-Gurion Airport", icon: <AirportIcon />, route: "/llbg" },
    { name: "Eilat-Ramon Airport", icon: <AirportIcon />, route: "/ller" },
    // { name: "Addons", icon: <DownloadIcon />, route: "" },
    // { name: "Slots System", icon: <AssignmentIcon />, route: "" },
  ];

  return (
    <React.Fragment>
      <ListSubheader component="div" style={{ backgroundColor: "#142d54", color: "white" }} >
        Pilots
      </ListSubheader>
      {itemsList.map((item) => {
        return (
          <Link to={item.route} style={{ textDecoration: 'none' }} key={item.route}>
            <ListItemButton sx={{
              color: "white",
              ":hover": {
                backgroundColor: "#142d54",
              }
            }}>
              <ListItemIcon sx={{ color: "white" }}>
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItemButton>
          </Link>
        )
      })}
    </React.Fragment>
  )

}

export function AtcItems() {
  const member = useSelector(selectMember);
  const itemsList = [
    { name: "ATC Booking", icon: <EventIcon />, route: "/atc/booking" },
    // { name: "Learning Materials", icon: <SchoolIcon />, route: "/atc/materials" },
  ];

  if (member.atcrating > 1 && member.division === "IL") {
    return (
      <React.Fragment>
        <ListSubheader component="div" style={{ backgroundColor: "#142d54", color: "white" }} >
          ATC
        </ListSubheader>
        {itemsList.map((item) => {
          return (
            <Link to={item.route} style={{ textDecoration: 'none' }} key={item.route}>
              <ListItemButton sx={{
                color: "white",
                ":hover": {
                  backgroundColor: "#142d54",
                }
              }}>
                <ListItemIcon sx={{ color: "white" }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItemButton>
            </Link>
          )
        })}
        <ListItemButton
          onClick={(e) => {
            window.open('http://files.aero-nav.com/LLLL', '_blank');
          }}
          sx={{
            color: "white",
            ":hover": {
              backgroundColor: "#142d54",
            }
          }}>
          <ListItemIcon sx={{ color: "white" }}>
            <DownloadIcon />
          </ListItemIcon>
          <ListItemText primary="Sector Files" />
        </ListItemButton>
        <ListItemButton
          onClick={(e) => {
            window.open('https://drive.google.com/drive/folders/1WbILh-TsJsb8ZWchvBVp_ZhTxFuM1jD2', '_blank');
          }}
          sx={{
            color: "white",
            ":hover": {
              backgroundColor: "#142d54",
            }
          }}>
          <ListItemIcon sx={{ color: "white" }}>
            <MicIcon />
          </ListItemIcon>
          <ListItemText primary="vAtis Files" />
        </ListItemButton>
        <ListItemButton
          onClick={(e) => {
            window.open('https://drive.google.com/drive/folders/1AOG_POjz3JHSLdUrAyitZVC2Ckq7-jAG', '_blank');
          }}
          sx={{
            color: "white",
            ":hover": {
              backgroundColor: "#142d54",
            }
          }}>
          <ListItemIcon sx={{ color: "white" }}>
            <SchoolIcon />
          </ListItemIcon>
          <ListItemText primary="Learning Materials" />
        </ListItemButton>
      </React.Fragment >
    )
  }

}
