import SendIcon from '@mui/icons-material/Send';
import LoadingButton from '@mui/lab/LoadingButton';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Title from '../../../../Title';
import { selectMember, selectToken } from '../../../../features/authSlice/authSlice';

export default function ModifyActive(props) {
    const { showModify, closeModifyForm, setIsLoading, atc } = props;
    const member = useSelector(selectMember);
    const token = useSelector(selectToken);
    const [name, setName] = useState();
    const [vid, setVid] = useState(false);
    const [active, setActive] = useState(false);
    const [positions, setPositions] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);
    const [submitButton, setSubmitButton] = useState(false);


    const handleSubmit = (e) => {
        setLoadingButton(true);
        setSubmitButton(true);
        var data = JSON.stringify({
            "vid": atc.vid,
            "active": active,
            "positions": positions
        });

        var config = {
            method: 'post',
            url: process.env.REACT_APP_VATIL_API + '/general/modifyatc',
            headers: {
                'Authorization': `Bearer ${token} `,
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                setTimeout(() => {
                    closeModifyForm();
                    setIsLoading(true);
                }, 3000)
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    const handleClose = (e) => {
        setName(null);
        setVid(null);
        setActive(null);
        closeModifyForm();
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };


    useEffect(() => {
        if (atc) {
            setName(atc.name);
            setVid(atc.vid);
            setPositions(atc.positions);
            if (atc.qualified === "Yes") {
                setActive(true);
            } else {
                setActive(false);
            }
        }
    }, [showModify, atc])


    return (
        <Modal
            open={showModify}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Stack component="form" noValidate spacing={3} sx={style}>
                <Title >Set Active</Title>
                <TextField
                    id="outlined-disabled"
                    label="Controller Name"
                    value={name}
                    InputLabelProps={{ shrink: name }}
                    margin="normal"
                    fullWidth
                    disabled

                />
                <TextField
                    id="outlined-disabled"
                    label="VATSIM ID"
                    value={vid}
                    InputLabelProps={{ shrink: vid }}
                    margin="normal"
                    fullWidth
                    disabled

                />
                <TextField
                    id="outlined-disabled"
                    label="Positions"
                    value={positions}
                    InputLabelProps={{ shrink: positions }}
                    margin="normal"
                    onChange={(e) => { setPositions(e.target.value) }}
                    fullWidth

                />
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Approved?</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={active}
                        label="Approved?"
                        onChange={(e) => {
                            setActive(e.target.value);
                        }}
                    >
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                    </Select>
                </FormControl>
                <LoadingButton
                    onClick={handleSubmit}
                    endIcon={<SendIcon />}
                    loading={loadingButton}
                    loadingPosition="end"
                    variant="contained"
                    disabled={submitButton}
                >
                    Update
                </LoadingButton>
            </Stack>
        </Modal >
    )
}