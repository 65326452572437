import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux'
import { selectMember, selectToken } from '../../features/authSlice/authSlice'
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Title from '../../Title'
import moment from 'moment'
import NewAtcBooking from '../AtcBooking/NewAtcBooking'
import RemoveAtcBooking from '../AtcBooking/RemoveAtcBooking'
import ModifyAtcBooking from '../AtcBooking/ModifyAtcBooking'

export default function AtcBooking() {
    const member = useSelector(selectMember);
    const token = useSelector(selectToken);
    const [bookings, setBookings] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [showForm, setShowForm] = useState(false);
    const [showCancel, setShowCancel] = useState(false);
    const [showModify, setShowModify] = useState(false);
    const [booking, setBooking] = useState({ callsign: "LLBG_DEL", start: "soon" });
    const [title, setTitle] = useState();



    const showNewBookingForm = () => {
        setShowForm(true);
    }

    const closeNewBookingForm = () => {
        setShowForm(false);
    }

    const closeCancelForm = () => {
        setShowCancel(false);
    }

    const closeModifyForm = () => {
        setShowModify(false);
    }

    const columnsrequests = [
        { field: 'callsign', headerName: 'Position', width: 200 },
        {
            field: 'start',
            headerName: 'Start',
            width: 250,
            renderCell: (cellValues) => {
                let date = moment(cellValues.row.start).format('DD/MM/yyyy HH:mm');
                return (
                    date
                )
            }
        },
        {
            field: 'end',
            headerName: 'End',
            width: 250,
            renderCell: (cellValues) => {
                let date = moment(cellValues.row.end).format('DD/MM/yyyy HH:mm');
                return (
                    date
                )
            }
        },
        {
            field: "actions",
            width: 220,
            headerName: 'Actions',
            renderCell: (cellValues) => {
                return (
                    <div>
                        <Button
                            sx={{ backgroundColor: "#0a1a40" }}
                            variant="contained"
                            onClick={async (e) => {
                                e.preventDefault();
                                setShowModify(true);
                                setBooking(cellValues.row);

                                // handleClick(event, cellValues);
                            }}
                        >
                            Modify
                        </Button>
                        <Button
                            sx={{ marginLeft: 1 }}
                            variant="contained"
                            color="error"
                            onClick={async (e) => {
                                e.preventDefault();
                                setBooking(cellValues.row);
                                setShowCancel(true);
                            }}
                        >
                            Cancel
                        </Button>
                    </div>
                );
            }
        }
    ];


    const loadingrows = [
        { callsign: 'loading', start: 'loading', end: 'loading' }
    ]





    useEffect(() => {
        var data = JSON.stringify({
            "vid": member.vid
        });

        var config = {
            method: 'post',
            url: process.env.REACT_APP_VATIL_API + '/calendar/getatcbookings',
            headers: {
                'Authorization': `Bearer ${token} `,
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                setBookings(response.data);
                setIsLoading(false);
            })
            .catch(function (error) {
                console.log(error);
            });

    }, [isLoading]);



    if (isLoading) {
        return (
            <div>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={8} lg={13}>
                        <Paper sx={{ width: 1500 }}>
                            <Title >My ATC Bookings</Title>
                            <Button
                                color="success"
                                variant="contained"
                                onClick={showNewBookingForm}
                            >
                                New Booking
                            </Button>
                            <div style={{ height: 600, width: '100%', marginTop: 10, marginLeft: 30 }}>
                                <DataGrid
                                    getRowId={() => Math.random()}
                                    rows={loadingrows}
                                    columns={columnsrequests}
                                    pageSize={5}
                                    rowsPerPageOptions={[5]}
                                    loading={isLoading}
                                />
                            </div>
                        </Paper>
                    </Grid>
                </Grid >
            </div>
        )
    } else {
        if (bookings.length > 0) {
            return (
                <div>
                    <Grid container spacing={3} >
                        <Grid item xs={12} md={8} lg={13}>
                            <Paper sx={{ width: 1200 }}>
                                <Title >My ATC Bookings</Title>
                                <Button
                                    color="success"
                                    variant="contained"
                                    onClick={showNewBookingForm}
                                >
                                    New Request
                                </Button>
                                <div style={{ height: 700, width: '95%', marginTop: 10, marginLeft: 29 }}>
                                    <DataGrid
                                        getRowId={() => Math.random()}
                                        rows={bookings}
                                        columns={columnsrequests}
                                        pageSize={15}
                                        rowsPerPageOptions={[15]}
                                        loading={isLoading}
                                    />
                                </div>
                            </Paper>
                        </Grid>
                    </Grid >
                    <NewAtcBooking showForm={showForm} closeNewBookingForm={closeNewBookingForm} setIsLoading={setIsLoading} />
                    <RemoveAtcBooking showCancel={showCancel} closeCancelForm={closeCancelForm} setIsLoading={setIsLoading} booking={booking} />
                    <ModifyAtcBooking showModify={showModify} closeModifyForm={closeModifyForm} setIsLoading={setIsLoading} booking={booking} />
                </div>
            )
        } else {
            return (
                <div>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={8} lg={13}>
                            <Paper >
                                <Title >My ATC Bookings</Title>
                                <Button
                                    color="success"
                                    variant="contained"
                                    onClick={showNewBookingForm}
                                >
                                    New Booking
                                </Button>
                                <h2>No ATC bookings exist</h2>
                            </Paper>
                        </Grid>
                    </Grid >
                    <NewAtcBooking showForm={showForm} closeNewBookingForm={closeNewBookingForm} setIsLoading={setIsLoading} />
                </div >
            )
        }

    }

}

